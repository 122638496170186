import React, { FC } from "react";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "../../../openapi/api-client";
import { TeamName } from "../../../Components/TeamName";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { Box, Table } from "@mantine/core";
import { TeamLogo } from "../../../Components/TeamLogo";

const tdStyle: React.CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: 24,
  fontFamily: "Chivo",
};

export const Leaderboard: FC = () => {
  const { competitionId, groupId } = useRequiredParams([
    "competitionId",
    "groupId",
  ]);

  const leaderboardData = useQuery({
    queryKey: ["leaderboard", { competitionId, groupId }],
    queryFn: async () => {
      const leaderboard = await getLeaderboard({ competitionId, groupId });
      return leaderboard.data;
    },
  });

  if (!leaderboardData.isSuccess) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div>
        <Table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 2px",
          }}
        >
          <Table.Thead>
            <Table.Tr
              style={{
                backgroundColor: "#194383",
                color: "white",
              }}
            >
              <Table.Th
                style={{
                  ...tdStyle,
                  textAlign: "left",
                  fontWeight: 900,
                  fontSize: 40,
                }}
                colSpan={3}
              >
                Vorrunden-Tabelle
              </Table.Th>
              <Table.Th
                style={{
                  ...tdStyle,
                  textAlign: "center",
                  paddingTop: 20,
                  paddingBottom: 20,
                  fontWeight: 900,
                }}
              >
                Tor-Diff.
              </Table.Th>
              <Table.Th
                style={{ ...tdStyle, textAlign: "center", fontWeight: 900 }}
              >
                Punkte
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {leaderboardData.data.leaderboard.map((l, index) => {
              return (
                <Table.Tr
                  key={l.teamId}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.95)",
                  }}
                >
                  <Table.Td style={{ ...tdStyle, textAlign: "right" }}>
                    {index + 1}.
                  </Table.Td>
                  <Table.Td>
                    <Box w={35}>
                      <TeamLogo height={35} teamId={l.teamId} />
                    </Box>
                  </Table.Td>
                  <Table.Td style={{ ...tdStyle, width: 500, fontWeight: 700 }}>
                    <TeamName teamId={l.teamId} />
                  </Table.Td>
                  <Table.Td style={{ ...tdStyle, textAlign: "center" }}>
                    {l.goalDifference}
                  </Table.Td>
                  <Table.Td style={{ ...tdStyle, textAlign: "center" }}>
                    {l.points}
                  </Table.Td>
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </div>
    </div>
  );
};
