import { FC, useContext } from "react";
import { Stack, Table, Title, Text, Box } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "../../../../openapi/api-client";
import { TeamName } from "../../../../Components/TeamName";
import { CenteredLoader } from "../../../../Components/CenteredLoader";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";
import { TeamLogo } from "../../../../Components/TeamLogo.tsx";

export const Leaderboard: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const groupId = import.meta.env.VITE_APP_LEADERBOARD_GROUP_ID;

  const leaderboardData = useQuery({
    queryKey: ["leaderboard", { competitionId, groupId }],
    queryFn: async () => {
      const data = await getLeaderboard({ competitionId, groupId });
      return data.data;
    },
  });

  return (
    <>
      <Stack gap="lg">
        <Title order={2}>Vorrundentabelle</Title>
        <Text>
          Die Tabelle errechnet sich auf Basis der Punkte, Tordifferenz und
          Anzahl geschossener Tore. Bei Gleichstand entscheidet der direkte
          Vergleich und ggf. ein 9-Meter-Schießen.
        </Text>
        {leaderboardData.isSuccess ? (
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th
                  style={{ textAlign: "right", width: "1%" }}
                ></Table.Th>
                <Table.Th
                  style={{ textAlign: "right", width: "1%" }}
                ></Table.Th>
                <Table.Th></Table.Th>
                <Table.Th style={{ textAlign: "center", width: "1%" }}>
                  Tore
                </Table.Th>
                <Table.Th style={{ textAlign: "center", width: "1%" }}>
                  D.
                </Table.Th>
                <Table.Th style={{ textAlign: "center", width: "1%" }}>
                  P.
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {leaderboardData.data.leaderboard.map((l, index) => (
                <Table.Tr key={l.teamId}>
                  <Table.Td style={{ textAlign: "right" }}>
                    {index + 1}.
                  </Table.Td>
                  <Table.Td>
                    <Box w={20}>
                      <TeamLogo teamId={l.teamId} height={20} />
                    </Box>
                  </Table.Td>
                  <Table.Td>
                    <Text lineClamp={1} style={{ wordBreak: "break-all" }}>
                      <TeamName teamId={l.teamId} />
                    </Text>
                  </Table.Td>
                  <Table.Td style={{ textAlign: "center" }}>
                    {l.scoredGoals}:{l.receivedGoals}
                  </Table.Td>
                  <Table.Td style={{ textAlign: "center" }}>
                    {l.goalDifference}
                  </Table.Td>
                  <Table.Td style={{ textAlign: "center" }}>
                    {l.points}
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        ) : (
          <CenteredLoader />
        )}
      </Stack>
    </>
  );
};
