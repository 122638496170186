import { AppShell, Burger, Group } from "@mantine/core";
import { Logo } from "../../Logo";
import { FC } from "react";
import {
  IconChairDirector,
  IconCommand,
  IconMicrophone,
  IconShirtSport,
  IconTournament,
  IconUser,
} from "@tabler/icons-react";
import { Outlet, useNavigate } from "react-router-dom";
import { NavItem } from "../../Components/NavItem";
import { useToggle } from "@mantine/hooks";

export const Control: FC = () => {
  const navigate = useNavigate();
  const [navbarOpen, toggleNavbarOpen] = useToggle<boolean>([false, true]);

  return (
    <AppShell
      padding="md"
      navbar={{
        width: 200,
        breakpoint: "md",
        collapsed: { mobile: !navbarOpen },
      }}
      header={{ height: 60 }}
      styles={(theme) => ({
        main: { backgroundColor: theme.colors.gray[0] },
      })}
    >
      <AppShell.Header>
        <Group style={{ height: "100%" }} px={20} justify="apart">
          <Logo onClick={() => {}} />
          <Burger
            opened={navbarOpen}
            onClick={() => toggleNavbarOpen()}
            hiddenFrom="md"
            size="md"
          />
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="xs" hidden={!navbarOpen}>
        <NavItem
          onClick={() => {
            toggleNavbarOpen(false);
            navigate("competitions");
          }}
          color="fwcBlue"
          icon={<IconTournament size="1rem" />}
          label="Turniere"
        />
        <NavItem
          onClick={() => {
            toggleNavbarOpen(false);
            navigate("teams");
          }}
          color="yellow"
          icon={<IconShirtSport size="1rem" />}
          label="Mannschaften"
        />
        <NavItem
          onClick={() => {
            toggleNavbarOpen(false);
            navigate("players");
          }}
          color="red"
          icon={<IconUser size="1rem" />}
          label="Spieler"
        />
        <NavItem
          onClick={() => {
            toggleNavbarOpen(false);
            navigate("timekeeper");
          }}
          color="cyan"
          icon={<IconCommand size="1rem" />}
          label="Zeitnehmer"
        />
        <NavItem
          onClick={() => {
            toggleNavbarOpen(false);
            navigate("regie");
          }}
          color="pink"
          icon={<IconChairDirector size="1rem" />}
          label="Regie"
        />
        <NavItem
          onClick={() => {
            toggleNavbarOpen(false);
            navigate("ledwall");
          }}
          color="lime"
          icon={<IconUser size="1rem" />}
          label="Ledwall"
        />
        <NavItem
          onClick={() => {
            toggleNavbarOpen(false);
            navigate("commentator");
          }}
          color="violet"
          icon={<IconMicrophone size="1rem" />}
          label="Kommentator"
        />
        <NavItem
          onClick={() => {
            toggleNavbarOpen(false);
            navigate("command");
          }}
          color="orange"
          icon={<IconCommand size="1rem" />}
          label="Commands"
        />
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};

export default Control;
