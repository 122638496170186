import React from "react";
import ReactDOM from "react-dom/client";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import { socket } from "./socket";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MantineProvider } from "@mantine/core";
import { Settings } from "luxon";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DeviceTags } from "./DeviceTagContext.tsx";

Settings.defaultLocale = "de";

const root = ReactDOM.createRoot(document.getElementById("root")!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: "always",
      refetchOnReconnect: "always",
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  },
});

socket.on("event", async (message) => {
  console.log(message.eventType, message.event.id);

  if (message.eventType.startsWith("game.")) {
    await queryClient.invalidateQueries({
      queryKey: ["game", { gameId: message.event.id }],
    });
    await queryClient.invalidateQueries({
      queryKey: ["games"],
    });
    await queryClient.invalidateQueries({
      queryKey: ["currentGame"],
    });

    if (message.eventType == "game.finished") {
      await queryClient.invalidateQueries({
        queryKey: ["leaderboard"],
      });
    }

    if (
      message.eventType == "game.goalScored" ||
      message.eventType == "game.goalRevoked"
    ) {
      await queryClient.invalidateQueries({
        queryKey: ["topscorer"],
      });
    }

    return;
  }

  if (message.eventType.startsWith("team.")) {
    await queryClient.invalidateQueries({
      queryKey: ["team", { teamId: message.event.id }],
    });
    await queryClient.invalidateQueries({
      queryKey: ["teams"],
    });
    return;
  }

  if (message.eventType.startsWith("player.")) {
    await queryClient.invalidateQueries({
      queryKey: ["player", { playerId: message.event.id }],
    });
    await queryClient.invalidateQueries({
      queryKey: ["players"],
    });
    return;
  }

  if (message.eventType.startsWith("competition.")) {
    await queryClient.invalidateQueries({
      queryKey: ["competition", { competitionId: message.event.id }],
    });
    await queryClient.invalidateQueries({
      queryKey: ["competitions"],
    });
    await queryClient.invalidateQueries({
      queryKey: ["leaderboard"],
    });
    return;
  }
});

root.render(
  <React.StrictMode>
    <MantineProvider
      theme={{
        fontFamily: "Chivo",
        fontFamilyMonospace: "Chivo Mono",
        colors: {
          fwcBlue: [
            "#eef3fc",
            "#dae4f2",
            "#b1c6e7",
            "#85a7dd",
            "#618cd5",
            "#4b7bd0",
            "#3f73cf",
            "#3262b7",
            "#285aa1",
            "#194383",
          ],
        },
        primaryColor: "fwcBlue",
      }}
    >
      <QueryClientProvider client={queryClient}>
        <DeviceTags>
          <RouterProvider router={routes} />
          <ReactQueryDevtools initialIsOpen={false} />
        </DeviceTags>
      </QueryClientProvider>
    </MantineProvider>
  </React.StrictMode>,
);
