import { Divider, AppShell } from "@mantine/core";
import { FC } from "react";
import { NavItem } from "../../../../Components/NavItem";
import {
  IconBallFootball,
  IconBrandInstagram,
  IconBrandYoutube,
  IconCalendarClock,
  IconExternalLink,
  IconMessageCircle,
  IconShirtSport,
  IconSoccerField,
  IconTable,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

type NavigationProps = {
  navbarOpened: boolean;
  closeNavbar: () => void;
};

export const Navigation: FC<NavigationProps> = (props) => {
  const { navbarOpened, closeNavbar } = props;

  const navigate = useNavigate();

  return (
    <AppShell.Navbar p="md" hidden={!navbarOpened}>
      <NavItem
        onClick={() => {
          navigate("/");
          closeNavbar();
        }}
        color="fwcBlue"
        icon={<IconSoccerField size="1rem" />}
        label="Startseite"
      />
      <NavItem
        onClick={() => {
          navigate("games");
          closeNavbar();
        }}
        color="fwcBlue"
        icon={<IconCalendarClock size="1rem" />}
        label="Spiele"
      />
      <NavItem
        onClick={() => {
          navigate("teams");
          closeNavbar();
        }}
        color="fwcBlue"
        icon={<IconShirtSport size="1rem" />}
        label="Mannschaften"
      />
      <NavItem
        onClick={() => {
          navigate("topscorer");
          closeNavbar();
        }}
        color="fwcBlue"
        icon={<IconBallFootball size="1rem" />}
        label="Topscorer"
      />
      <NavItem
        onClick={() => {
          navigate("leaderboard");
          closeNavbar();
        }}
        color="fwcBlue"
        icon={<IconTable size="1rem" />}
        label="Vorrundentabelle"
      />
      <Divider my="sm" />
      <NavItem
        onClick={() => {
          window.open("https://freewaycup.de/app-feedback/", "_blank");
        }}
        color="fwcBlue"
        icon={<IconMessageCircle size="1rem" />}
        label="Feedback zur App"
      />
      <Divider my="sm" />
      <NavItem
        onClick={() => {
          window.open("https://www.freewaycup.de", "_blank");
        }}
        color="fwcBlue"
        icon={<IconExternalLink size="1rem" />}
        label="Zur Website"
      />
      <NavItem
        onClick={() => {
          window.open(
            "https://freewaycup.de/wp-content/uploads/2024/12/FWC_2025_Magazin_aktuell_72.pdf",
            "_blank",
          );
        }}
        color="fwcBlue"
        icon={<IconExternalLink size="1rem" />}
        label="Turnier-Magazin"
      />
      <NavItem
        onClick={() => {
          window.open(
            "https://www.instagram.com/freewaycup_official/",
            "_blank",
          );
        }}
        color="fwcBlue"
        icon={<IconBrandInstagram size="1rem" />}
        label="Instagram"
      />
      <NavItem
        onClick={() => {
          window.open("https://www.youtube.com/user/FreewayCup", "_blank");
        }}
        color="fwcBlue"
        icon={<IconBrandYoutube size="1rem" />}
        label="Youtube"
      />
    </AppShell.Navbar>
  );
};
