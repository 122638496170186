import { FC } from "react";
import { Stack } from "@mantine/core";
import { Stream } from "../Widgets/Stream";
import { UpcomingGames } from "../Widgets/UpcomingGames";
import { Topscorer } from "../Widgets/Topscorer";
import { LastGames } from "../Widgets/LastGames.tsx";
import { SponsorAd } from "../../../../Components/SponsorAd.tsx";

export const Homepage: FC<{ isDesktop?: boolean }> = (props) => {
  const { isDesktop } = props;

  return (
    <Stack gap="lg">
      {!isDesktop && (
        <>
          <Stream />
          <SponsorAd />
        </>
      )}
      <UpcomingGames />
      <LastGames />
      <Topscorer number={3} />
    </Stack>
  );
};
