import { FC } from "react";
import { Page } from "../../../Components/Page";
import {
  ActionIcon,
  Button,
  Group,
  Indicator,
  Loader,
  Skeleton,
  Table,
  Text,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { listGamesByCompetition } from "../../../openapi/api-client";
import { components } from "../../../openapi/generated-types";
import { DateTime } from "luxon";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { ResolvedTeamReference } from "../../../Components/ResolvedTeamReference";
import { IconEdit } from "@tabler/icons-react";
import { TeamName } from "../../../Components/TeamName";
import { Time } from "../../../Components/Time";
import { useCompetition } from "../../../hooks/useCompetition.ts";

type TableRowProps = {
  game: components["schemas"]["Game"];
};

const TableRow: FC<TableRowProps> = (props) => {
  const { game } = props;
  const navigate = useNavigate();

  const competition = useCompetition(game.competitionId);

  if (!competition.isSuccess) {
    return (
      <Table.Tr>
        <Table.Td>
          <Skeleton height="16px" />
        </Table.Td>
        <Table.Td>
          <Skeleton height="16px" />
        </Table.Td>
        <Table.Td>
          <Skeleton height="16px" mb="4px" />
          <Skeleton height="16px" />
        </Table.Td>
        <Table.Td>
          <Skeleton height="16px" mb="4px" />
          <Skeleton height="16px" />
        </Table.Td>
        <Table.Td>
          <Skeleton height="16px" />
        </Table.Td>
        <Table.Td>
          <Skeleton height="16px" />
        </Table.Td>
        <Table.Td>
          <Skeleton height="16px" />
        </Table.Td>
        <Table.Td>
          <Skeleton height="16px" />
        </Table.Td>
        <Table.Td>
          <Skeleton height="16px" />
        </Table.Td>
      </Table.Tr>
    );
  }

  const group = competition.data.groups.find((g) => g.id == game.groupId);
  const matchday = competition.data.matchdays.find(
    (m) => m.id == game.matchdayId,
  );
  const date = DateTime.fromISO(game.dateTime);

  return (
    <Table.Tr>
      <Table.Td>
        <Link to={"games/" + game.id}>{game.name}</Link>
      </Table.Td>
      <Table.Td>
        <Group>
          {date.toFormat("dd.MM., HH:mm")} Uhr{" "}
          {!game.started && (
            <ActionIcon>
              <IconEdit
                size={18}
                onClick={() => navigate(`games/${game.id}/change-datetime`)}
              />
            </ActionIcon>
          )}
        </Group>
      </Table.Td>
      <Table.Td>
        {game.actualTeams.homeTeamId ? (
          <TeamName teamId={game.actualTeams.homeTeamId} />
        ) : (
          "noch nicht bestätigt"
        )}
        <Text c="dimmed">
          <ResolvedTeamReference
            teamReference={game.plannedTeams.home}
            competitionId={competition.data.id}
          />
        </Text>
      </Table.Td>
      <Table.Td>
        {game.actualTeams.guestTeamId ? (
          <TeamName teamId={game.actualTeams.guestTeamId} />
        ) : (
          "noch nicht bestätigt"
        )}
        <Text c="dimmed">
          <ResolvedTeamReference
            teamReference={game.plannedTeams.guest}
            competitionId={competition.data.id}
          />
        </Text>
      </Table.Td>
      <Table.Td>{group ? group.name : ""}</Table.Td>
      <Table.Td>{matchday ? matchday.name : ""}</Table.Td>
      <Table.Td>
        <Group>
          <Time time={game.duration} />
          {!game.started && (
            <ActionIcon>
              <IconEdit
                size={18}
                onClick={() => navigate(`games/${game.id}/change-duration`)}
              />
            </ActionIcon>
          )}
        </Group>
      </Table.Td>
      <Table.Td>
        <Group>
          {game.shootOutShotsNumber}
          {!game.started && (
            <ActionIcon>
              <IconEdit
                size={18}
                onClick={() =>
                  navigate(`games/${game.id}/change-shoot-out-shots-number`)
                }
              />
            </ActionIcon>
          )}
        </Group>
      </Table.Td>
      <Table.Td>
        <Indicator
          disabled={!(game.started && !game.result)}
          color="red"
          processing
          position="middle-end"
        >
          {game.started && (
            <>
              {game.score.home}:{game.score.guest}
            </>
          )}
          {game.shootOutStarted && game.shootOutRounds && (
            <>
              <> </>(
              {game.shootOutRounds.reduce(
                (previous, round) =>
                  round.home?.success ? previous + 1 : previous,
                0,
              )}
              :
              {game.shootOutRounds.reduce(
                (previous, round) =>
                  round.guest?.success ? previous + 1 : previous,
                0,
              )}
              )
            </>
          )}
          {!game.started && <Text c="dimmed">-</Text>}
        </Indicator>
      </Table.Td>
    </Table.Tr>
  );
};

export const CompetitionDetail: FC = () => {
  const { competitionId } = useRequiredParams(["competitionId"]);
  const navigate = useNavigate();
  const competitionData = useCompetition(competitionId);

  const games = useQuery({
    queryKey: ["games", { competitionId }],
    queryFn: async () => {
      const response = await listGamesByCompetition({
        competitionId,
      });
      return response.data;
    },
  });

  if (!games.isSuccess || !competitionData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={competitionData.data.name}>
      <Button.Group>
        <Button mb="md" onClick={() => navigate("games/new")}>
          Neues Spiel
        </Button>
        <Button mb="md" onClick={() => navigate("teams/enroll")}>
          Mannschaften eintragen
        </Button>
      </Button.Group>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Zeit</Table.Th>
            <Table.Th>Heim</Table.Th>
            <Table.Th>Gast</Table.Th>
            <Table.Th>Gruppe</Table.Th>
            <Table.Th>Matchday</Table.Th>
            <Table.Th>Dauer</Table.Th>
            <Table.Th>9 m</Table.Th>
            <Table.Th>Ergebnis</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {games.data.games.map((g) => (
            <TableRow key={g.id} game={g} />
          ))}
        </Table.Tbody>
      </Table>
    </Page>
  );
};
