import { FC, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getTopscorer } from "../../../../openapi/api-client";
import {
  Button,
  Table,
  Title,
  Text,
  Box,
  Skeleton,
  Stack,
  ButtonGroup,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { PlayerName } from "../../../../Components/PlayerName";
import { PlayerNumber } from "../../../../Components/PlayerNumber";
import { CenteredLoader } from "../../../../Components/CenteredLoader";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";
import { usePlayer } from "../../../../hooks/usePlayer.ts";
import { TeamLogo } from "../../../../Components/TeamLogo.tsx";
import { IconShirtSport } from "@tabler/icons-react";

type TopscorerProps = {
  number?: number;
};

export const Topscorer: FC<TopscorerProps> = (props) => {
  const { number } = props;

  const competitionId = useContext(CompetitionContext);
  const navigate = useNavigate();

  const topscorerData = useQuery({
    queryKey: ["topscorer", { competitionId }],
    queryFn: async () => {
      const topscorer = await getTopscorer({ competitionId });
      return topscorer.data;
    },
  });

  const topscorer = topscorerData.data?.topscorer.slice(0, number);

  // do not show empty list when used as widget
  if (topscorer?.length == 0 && number != undefined) {
    return <></>;
  }

  return (
    <Stack gap="lg">
      <Title order={2}>Unsere Topscorer</Title>

      {number == undefined && (
        <Text>Tore und Assists werden gleich gewichtet.</Text>
      )}

      {topscorer ? (
        <Table style={{ textAlign: "center" }}>
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ textAlign: "right", width: "1%" }}></Table.Th>
              <Table.Th></Table.Th>
              <Table.Th style={{ width: "1%" }}></Table.Th>
              <Table.Th style={{ width: "1%" }}>
                <IconShirtSport height={16} />
              </Table.Th>
              <Table.Th style={{ textAlign: "center", width: "1%" }}>
                T
              </Table.Th>
              <Table.Th style={{ textAlign: "center", width: "1%" }}>
                A
              </Table.Th>
              <Table.Th style={{ textAlign: "center", width: "1%" }}>
                S
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {topscorer.map((t, index) => (
              <PlayerRow
                playerId={t.playerId}
                goals={t.goals}
                assists={t.assists}
                position={index + 1}
                key={t.playerId}
              />
            ))}
          </Table.Tbody>
        </Table>
      ) : (
        <CenteredLoader />
      )}
      {number != undefined && (
        <ButtonGroup>
          <Button color="fwcBlue.9" onClick={() => navigate("topscorer")}>
            Alle Scorer
          </Button>
        </ButtonGroup>
      )}
    </Stack>
  );
};

const PlayerRow: FC<{
  playerId: string;
  goals: number;
  assists: number;
  position: number;
}> = (props) => {
  const { playerId, goals, assists, position } = props;

  const playerData = usePlayer(playerId);

  const navigate = useNavigate();

  return (
    <Table.Tr>
      <Table.Td style={{ textAlign: "right" }}>{position}.</Table.Td>
      <Table.Td style={{ textAlign: "left" }}>
        <Text lineClamp={1} style={{ wordBreak: "break-all" }}>
          <PlayerName firstname playerId={playerId} />
        </Text>
      </Table.Td>
      <Table.Td>
        <Box
          w={20}
          h={20}
          onClick={() => {
            if (!playerData.isSuccess) {
              return;
            }

            navigate(`/teams/${playerData.data.teamId}`);
          }}
          style={{ cursor: "pointer" }}
        >
          {playerData.isSuccess ? (
            <TeamLogo teamId={playerData.data.teamId} height={20} />
          ) : (
            <Skeleton h={20} w={20} />
          )}
        </Box>
      </Table.Td>
      <Table.Td>
        <PlayerNumber playerId={playerId} />
      </Table.Td>
      <Table.Td>{goals}</Table.Td>
      <Table.Td>{assists}</Table.Td>
      <Table.Td>{assists + goals}</Table.Td>
    </Table.Tr>
  );
};
