import { FC, useEffect, useState } from "react";
import {
  Button,
  Loader,
  Select,
  Stack,
  Switch,
  TextInput,
  Title,
} from "@mantine/core";
import { Page } from "../../../Components/Page";
import { Display } from "./Components/1x1024x190/Display";
import { Display as HugeDisplay } from "./Components/2x1920x144/Display";
import { useFullscreen, useToggle } from "@mantine/hooks";
import { IconScreenShare } from "@tabler/icons-react";
import { useCommand } from "../../../hooks/useCommand.ts";
import {
  CompetitionCommand,
  LedwallActivityItemDisable,
  LedwallActivityItemSelect,
  LedwallDisable,
  LedwallEnable,
} from "../../../commandTypes.ts";
import { useCompetitionList } from "../../../hooks/useCompetitionList.ts";
import { activityItems } from "./activityItems.ts";

type LedwallSpecs = {
  id: string;
  numDisplays: number;
  displayWidth: number;
  displayHeight: number;
};

const ledwalls: LedwallSpecs[] = [
  {
    id: "1",
    numDisplays: 2,
    displayHeight: 144,
    displayWidth: 1920,
  },
  {
    id: "2",
    numDisplays: 1,
    displayHeight: 190,
    displayWidth: 1024,
  },
];

export const Ledwall: FC = () => {
  const [competitionId, setCompetitionId] = useState<string>(
    import.meta.env.VITE_APP_COMPETITION_ID,
  );
  const [text, setText] = useState<string>("");
  const [ledwallEnabled, toggleLedwallEnabled] = useToggle([true, false]);
  const [activityItemId, setActivityItemId] = useState<string | null>(null);
  const [selectedLedwallId, setSelectedLedwallId] = useState<string>("1");
  const { fullscreen, toggle } = useFullscreen();

  const selectedLedwall = ledwalls.find((l) => l.id == selectedLedwallId);

  useCommand<LedwallDisable>("ledwall.disable", () => {
    toggleLedwallEnabled(false);
  });

  useCommand<LedwallEnable>("ledwall.enable", () => {
    toggleLedwallEnabled(true);
  });

  useCommand<LedwallActivityItemSelect>("ledwall.activityItem.select", (c) => {
    setActivityItemId(c.activityItemId);
  });

  useCommand<LedwallActivityItemDisable>("ledwall.activityItem.disable", () => {
    setActivityItemId(null);
  });

  useCommand<CompetitionCommand>("competition", (command) => {
    setCompetitionId(command.competitionId);
  });

  // preload activity items
  useEffect(() => {
    activityItems.forEach((a) => {
      new Image().src = a.src;
    });
  }, []);

  const competitionData = useCompetitionList();

  if (!competitionData.isSuccess) {
    return <Loader />;
  }

  return (
    <div
      style={{
        paddingTop: fullscreen
          ? (selectedLedwall?.displayHeight || 0) *
              (selectedLedwall?.numDisplays || 0) -
            50
          : 0,
      }}
    >
      <Page title="Ledwall">
        <Stack mt="lg" align="flex-start" justify="flex-start">
          {!fullscreen && (
            <Select
              label="Ledwall"
              value={selectedLedwallId}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                setSelectedLedwallId(value);
              }}
              data={ledwalls.map((l) => ({
                value: l.id,
                label: `${l.numDisplays} x ${l.displayWidth}x${l.displayHeight}`,
              }))}
            />
          )}

          <Select
            label="Turnier"
            value={competitionId}
            searchable
            clearable={false}
            onChange={(c) => {
              if (!c) {
                return;
              }
              setCompetitionId(c);
            }}
            data={competitionData.data.competitions.map((c) => ({
              value: c.id,
              label: c.name,
            }))}
          />

          <Select
            label="Aktionsfläche"
            value={activityItemId}
            clearable
            searchable
            onChange={(v) => setActivityItemId(v)}
            data={activityItems.map((activity) => ({
              value: activity.id,
              label: activity.label,
            }))}
          />

          <TextInput
            label="Text-Anzeige"
            value={text}
            onChange={(event) => setText(event.currentTarget.value)}
          />

          <Switch
            label="Ledwall aktiv"
            onLabel="an"
            offLabel="aus"
            size="lg"
            checked={ledwallEnabled}
            onChange={() => toggleLedwallEnabled()}
          />

          {!fullscreen && (
            <div>
              <Button
                onClick={() => toggle()}
                color="green"
                leftSection={<IconScreenShare />}
              >
                Vollbild
              </Button>
            </div>
          )}

          {!fullscreen && <Title order={3}>Vorschau</Title>}

          {selectedLedwallId == "1" && (
            <HugeDisplay
              competitionId={competitionId}
              text={text != "" ? text : undefined}
              activityItem={activityItems.find((a) => a.id == activityItemId)}
              disabled={!ledwallEnabled}
            />
          )}

          {selectedLedwallId == "2" && (
            <Display
              competitionId={competitionId}
              text={text != "" ? text : undefined}
              disabled={!ledwallEnabled}
            />
          )}
        </Stack>
      </Page>
    </div>
  );
};
