import { operations } from "./openapi/generated-types";
import {
  createCompetition,
  createCompetitionGroup,
  createCompetitionMatchday,
  createPlayer,
  createTeam,
  enrollTeam,
  planGame,
} from "./openapi/api-client";
import { v4 as uuid } from "uuid";
import { DateTime } from "luxon";
import { players } from "./fwc-2025-players.ts";
import sleep from "sleep-promise";

type CreateTeam =
  operations["team.write.create"]["requestBody"]["content"]["application/json"];
type CreateTurnier =
  operations["competition.write.create"]["requestBody"]["content"]["application/json"];
type CreateMatchday =
  operations["competition.write.create-matchday"]["requestBody"]["content"]["application/json"];
type CreateGroup =
  operations["competition.write.create-group"]["requestBody"]["content"]["application/json"];
type PlanGame =
  operations["game.write.plan"]["requestBody"]["content"]["application/json"];

export const setupFWC2025 = async () => {
  // turnier
  const fwc: CreateTurnier = {
    id: "3d94c2b3-6507-4ded-9b2f-25ccc8c28546",
    name: "FreewayCup 2025",
    start: DateTime.fromISO("2025-01-18").toISO(),
    end: DateTime.fromISO("2025-01-19").toISO(),
    location: "Merkur-Arena Lübbecke",
  };
  await createCompetition(fwc);

  // teams
  const fwcTeams: Record<
    | "mka"
    | "fcb"
    | "fcu"
    | "bsc"
    | "koe"
    | "bmg"
    | "boc"
    | "s04"
    | "svw"
    | "hsv"
    | "bvb"
    | "m05"
    | "rbl"
    | "b04"
    | "vfb"
    | "dsc",
    CreateTeam
  > = {
    mka: {
      id: "5701917b-6997-4127-b66c-1d4e6d517994",
      name: "Mühlenkreis",
      shortName: "MKA",
      primaryColor: "#004f90",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/muehlenkreisauswahl.svg",
      sponsor: {
        name: "Volksbank PLUS",
        url: "https://www.vbplus.de",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/vb-plus.svg",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/vb-plus.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/muehlenkreisauswahl.jpg",
        description: "",
      },
      staff:
        "Tasso Keller (Trainer), Björn Kenter (Trainer), Dirk Kemmener (Aufwärm- und Koordinations-Trainer), Isabel Keller (Physiotherapeutin), Rafael Keller (Betreuer)",
    },
    bsc: {
      id: "2dfb964c-e7e4-46f2-a060-394ca6eba972",
      name: "Hertha BSC",
      shortName: "BSC",
      primaryColor: "#004d9e",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/hertha-bsc-berlin.svg",
      sponsor: {
        name: "Bäckerei Schmidt",
        url: "https://baeckerei-schmidt.de/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/baeckerei-schmidt.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/baeckerei-schmidt.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/hertha-bsc-berlin.jpg",
        description: "",
      },
      staff:
        "Alexander Arsovic (Cheftrainer), Damir Bektic, Holger Lindemann (Co-Trainer), Marijana Herrmann (Athletiktrainerin)",
    },
    rbl: {
      id: "7004db4b-e8c5-4e5a-aaf7-48f841b9f40a",
      name: "RB Leipzig",
      shortName: "RBL",
      primaryColor: "#DD013F",
      logoFilePath: "https://app-assets.freewaycup.de/teamlogo/rb-leipzig.svg",
      sponsor: {
        name: "LSM event & messeservice",
        url: "https://www.lsm-gmbh.de/",
        logoFilePath: "https://app-assets.freewaycup.de/sponsorlogo/lsm.svg",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/lsm.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/rb-leipzig.jpg",
        description: "",
      },
      staff:
        "Dennis Bockelmann (Cheftrainer), Alexander Portleroy (Co-Trainer), Thomas Risch (Spielanalyse), Felix Hilke (Physiotherapeut), Bartosch Gaul (Sportlicher Leiter)",
    },
    m05: {
      id: "6d32bda8-fb2b-49cc-b2fa-9750905965b4",
      name: "1. FSV Mainz 05",
      shortName: "M05",
      primaryColor: "#C3141E",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/1-fsv-mainz-05.svg",
      sponsor: {
        name: "Plümat",
        url: "https://www.pluemat.info/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/pluemat.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/pluemat.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/1-fsv-mainz-05.jpg",
        description: "",
      },
      staff:
        "Yannick Dreyer (Cheftrainer), Alexander Schraut (Co-Trainer), Felix Bleutgen (Teammanager), Dominik Waritschlager (Physiotherapeut), Benjamin Bletry (Athletiktrainer)",
    },
    dsc: {
      id: "c049705d-fa24-4ddb-969e-e8a8673cd62d",
      name: "DSC Arminia Bielefeld",
      shortName: "DSC",
      primaryColor: "#004e95",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/dsc-arminia-bielefeld.svg",
      sponsor: {
        name: "Schulz Gebäudeservice",
        url: "https://www.sgbs.de/",
        logoFilePath: "https://app-assets.freewaycup.de/sponsorlogo/schulz.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/schulz.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/dsc-arminia-bielefeld.jpg",
        description: "",
      },
      staff:
        "Gabriel Imran (Cheftrainer), Nujin Hassan, Tobias Fraedrich (Co-Trainer), Adem Özkan, Sehrii Zaloha (Teambetreuer), Till Niermann (Athletiktrainer), Yannic Barteldrees (Physiotherapeut)",
    },
    vfb: {
      id: "20829cb9-e3ea-4cee-b717-6a45bffe2cea",
      name: "VfB Stuttgart",
      shortName: "VfB",
      primaryColor: "#E32219",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/vfb-stuttgart.svg",
      sponsor: {
        name: "HARTING",
        url: "https://www.harting.com/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/harting.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/harting.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/vfb-stuttgart.jpg",
        description: "",
      },
      staff:
        "Andreas Weinberger (Cheftrainer), Marco Bräuninger (Athletiktrainer), Fabian Becker (Physiotherapeut)",
    },
    koe: {
      id: "fbb15df4-a2aa-4f62-a79c-c3d762a4a226",
      name: "1. FC Köln",
      shortName: "KOE",
      primaryColor: "#ED1C24",
      logoFilePath: "https://app-assets.freewaycup.de/teamlogo/1-fc-koeln.svg",
      sponsor: {
        name: "terra",
        url: "https://www.wortmann.de/",
        logoFilePath: "https://app-assets.freewaycup.de/sponsorlogo/terra.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/terra.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/1-fc-koeln.jpg",
        description: "",
      },
      staff:
        "Vladyslav Moschenski (Cheftrainer), Nestor Londji (Co-Trainer), Timo Lawinger (Teambetreuer), Marcel Bergen, (Physiotherapeut)",
    },
    s04: {
      id: "fe9f4173-48ed-4bb8-97bf-2832cf9ca0c6",
      name: "FC Schalke 04",
      shortName: "S04",
      primaryColor: "#004d9d",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/fc-schalke-04.svg",
      sponsor: {
        name: "FR. AUSSIEKER metallverarbeitung",
        url: "https://www.fr-aussieker.de/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/aussieker.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/aussieker.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/fc-schalke-04.jpg",
        description: "",
      },
      staff:
        "Jörg Behnert (Trainer), Nils Schulz (Co-Trainer), Bekim Gerxhaliu (Betreuer), Eva Kempa (Physiotherapeutin)",
    },
    bvb: {
      id: "23f97164-d70b-459a-846f-3d0a23802462",
      name: "Borussia Dortmund",
      shortName: "BVB",
      primaryColor: "#FDE100",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/borussia-dortmund.svg",
      sponsor: {
        name: "Heinzig Metalltechnik",
        url: "https://www.heinzig.com/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/heinzig.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/heinzig.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/borussia-dortmund.jpg",
        description: "",
      },
      staff:
        "Marco Lehmann (Cheftrainer), Sahin Kösecik (Co-Trainer), Fynn Fust (Videoanalyst), Goran Pantic (Physiotherapeut), Jan-Pascal Reckert (Torwarttrainer), Gordon Schulze (Betreuer)",
    },
    svw: {
      id: "91fcd045-c8b7-4eb9-9a08-7270366df90e",
      name: "SV Werder Bremen",
      shortName: "SVW",
      primaryColor: "#1D9053",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/werder-bremen.svg",
      sponsor: {
        name: "Fahrschule Delil",
        url: "https://fahrschule-delil.de/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/fahrschule-delil.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/fahrschule-delil.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/werder-bremen.jpg",
        description: "",
      },
      staff:
        "Lars Uder (Trainer), Tim Müller, Lennart Johanns (Co-Trainer), Abigail Looi (Sportpsychologin), Mahmoud Alaia (Physiotherapeut),  Oliver Bastian (Betreuer), Finn Jaensch (Sportlicher Leiter)",
    },
    fcb: {
      id: "2cf68047-ff20-4eda-8775-e708c633c974",
      name: "FC Bayern München",
      shortName: "FCB",
      primaryColor: "#DC052D",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/fc-bayern-muenchen.svg",
      sponsor: {
        name: "Schneider Gastronomie & Catering",
        url: "https://www.schneider-gc.de",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/schneider-gc.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/schneider-gc.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/fc-bayern-muenchen.jpg",
        description: "",
      },
      staff:
        "Can Ünal (Cheftrainer), Florian Mayer (Co-Trainer), Alina Leuchtenberger (Physiotherapeutin), Miroslav Todorov (Teambetreuer), Yannick Wloka (Teammanager)",
    },
    b04: {
      id: uuid(),
      name: "Bayer Leverkusen",
      shortName: "B04",
      primaryColor: "#E32221",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/bayer-04-leverkusen.svg",
      sponsor: {
        name: "Autohaus Schmale",
        url: "https://www.autohaus-schmale.de/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/autohaus-schmale.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/autohaus-schmale.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/bayer-04-leverkusen.jpg",
        description: "",
      },
      staff:
        "Benjamin Adam (Cheftrainer), Albert Bunjaku (Co-Trainer), Damir Ivosevi (Torwarttrainer), King Midas-Ntem (Physiotherapeut), Andreas Witzoreck (Busfahrer)",
    },
    fcu: {
      id: "8bb71f70-714a-47bc-9a83-8233bec06c28",
      name: "Union Berlin",
      shortName: "FCU",
      primaryColor: "#EB1923",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/1-fc-union-berlin.svg",
      sponsor: {
        name: "Union Investment",
        url: "https://www.union-investment.de/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/union-investment.svg",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/union-investment.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/1-fc-union-berlin.jpg",
        description: "",
      },
      staff:
        "Andreas Kerwin (Cheftrainer), Nick Wappler (Co-Trainer), Jan Westphal (Physiotherapeut), Klaus Petke (Mannschaftsleiter)",
    },
    hsv: {
      id: "456029a4-98e0-4180-a3e5-e429bde658dc",
      name: "Hamburger SV",
      shortName: "HSV",
      primaryColor: "#0A3F86",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/hamburger-sv.svg",
      sponsor: {
        name: "Schneider Automobile",
        url: "https://schneider.seat.de/",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/schneider-automobile.jpg",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/schneider-automobile.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/hamburger-sv.jpg",
        description: "",
      },
      staff:
        "Tim Reddersen (Cheftrainer), Levon Airapetian, Soeren Bertram (Co-Trainer), Karsten Boehmer (Torwarttrainer), Stefan Knop (Physiotherapeut)",
    },
    boc: {
      id: "256cdf47-0e9e-4b46-ba57-8913ba186d14",
      name: "VfL Bochum",
      shortName: "BOC",
      primaryColor: "#005CA9",
      logoFilePath: "https://app-assets.freewaycup.de/teamlogo/vfl-bochum.svg",
      sponsor: {
        name: "mittwald",
        url: "https://www.mittwald.de",
        logoFilePath:
          "https://app-assets.freewaycup.de/sponsorlogo/mittwald_wordmark_claim_navy_rgb.svg",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/mittwald.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/vfl-bochum.jpg",
        description: "",
      },
      staff:
        "Florian Jungwirth (Cheftrainer), Christian März (Co-Trainer), Klaus Wohlfahrt (Teambetreuer), Alyssa Kolender (Physiotherapeutin)",
    },
    bmg: {
      id: "8feaf5af-553b-46f9-ab11-50992bbcd1b4",
      name: "Borussia Mönchengladbach",
      shortName: "BMG",
      primaryColor: "#000000",
      logoFilePath:
        "https://app-assets.freewaycup.de/teamlogo/borussia-moenchengladbach.svg",
      sponsor: {
        name: "Di Meo - Haustechnik",
        url: "https://dimeo-elektro.de/",
        logoFilePath: "https://app-assets.freewaycup.de/sponsorlogo/dimeo.png",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/dimeo.jpg",
      },
      photo: {
        filePath:
          "https://app-assets.freewaycup.de/teamphoto/2025/borussia-moenchengladbach.jpg",
        description: "",
      },
      staff:
        "Felix Galli (Cheftrainer), Nils Wüsten, (Co-Trainer), Martin Kompalla (Torwarttrainer), Sascha Kreutzer (Physiotherapeut), Murat Mete (Betreuer)",
    },
  };

  const createTeams = Object.values(fwcTeams).map(async (t) => {
    await createTeam(t);
    await enrollTeam({
      id: fwc.id,
      teamId: t.id,
    });
  });
  await Promise.all(createTeams);

  // group
  const groupVorrunde: CreateGroup = {
    id: fwc.id,
    groupId: "62ee3c0e-acc2-4fc7-b9d3-7251ffa650c4",
    name: "Vorrunde",
    teamIds: Object.values(fwcTeams).map((t) => t.id),
  };
  await createCompetitionGroup(groupVorrunde);

  // matchdays
  const matchdays: Record<
    | "vorrunde"
    | "achtelfinale"
    | "viertelfinale"
    | "halbfinale"
    | "kleinesfinale"
    | "finale",
    CreateMatchday
  > = {
    vorrunde: {
      id: fwc.id,
      matchdayId: uuid(),
      name: "Vorrunde",
    },
    achtelfinale: {
      id: fwc.id,
      matchdayId: uuid(),
      name: "Achtelfinale",
    },
    viertelfinale: {
      id: fwc.id,
      matchdayId: uuid(),
      name: "Viertelfinale",
    },
    halbfinale: {
      id: fwc.id,
      matchdayId: uuid(),
      name: "Halbfinale",
    },
    kleinesfinale: {
      id: fwc.id,
      matchdayId: uuid(),
      name: "Kleines Finale",
    },
    finale: {
      id: fwc.id,
      matchdayId: uuid(),
      name: "Finale",
    },
  };

  await createCompetitionMatchday(matchdays.vorrunde);
  await createCompetitionMatchday(matchdays.achtelfinale);
  await createCompetitionMatchday(matchdays.viertelfinale);
  await createCompetitionMatchday(matchdays.halbfinale);
  await createCompetitionMatchday(matchdays.kleinesfinale);
  await createCompetitionMatchday(matchdays.finale);

  // games
  const gamesVorrunde: PlanGame[] = [
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 01",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.dsc.id,
        },
        guest: {
          teamId: fwcTeams.fcb.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T12:00:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 02",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.bvb.id,
        },
        guest: {
          teamId: fwcTeams.m05.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T12:15:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 03",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.boc.id,
        },
        guest: {
          teamId: fwcTeams.vfb.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T12:30:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 04",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.mka.id,
        },
        guest: {
          teamId: fwcTeams.rbl.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T12:45:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 05",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.bmg.id,
        },
        guest: {
          teamId: fwcTeams.fcu.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T13:00:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 06",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.s04.id,
        },
        guest: {
          teamId: fwcTeams.bsc.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T13:15:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 07",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.koe.id,
        },
        guest: {
          teamId: fwcTeams.hsv.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T13:30:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 08",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.b04.id,
        },
        guest: {
          teamId: fwcTeams.svw.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T13:45:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 09",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.dsc.id,
        },
        guest: {
          teamId: fwcTeams.fcu.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T14:15:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 10",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.bvb.id,
        },
        guest: {
          teamId: fwcTeams.bsc.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T14:30:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 11",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.mka.id,
        },
        guest: {
          teamId: fwcTeams.hsv.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T14:45:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 12",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.boc.id,
        },
        guest: {
          teamId: fwcTeams.svw.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T15:00:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 13",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.rbl.id,
        },
        guest: {
          teamId: fwcTeams.koe.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T15:15:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 14",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.m05.id,
        },
        guest: {
          teamId: fwcTeams.bmg.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T15:30:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 15",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.fcb.id,
        },
        guest: {
          teamId: fwcTeams.b04.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T15:45:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 16",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.vfb.id,
        },
        guest: {
          teamId: fwcTeams.s04.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T16:00:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 17",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.rbl.id,
        },
        guest: {
          teamId: fwcTeams.bvb.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T16:40:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 18",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.m05.id,
        },
        guest: {
          teamId: fwcTeams.boc.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T16:55:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 19",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.fcb.id,
        },
        guest: {
          teamId: fwcTeams.mka.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T17:10:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 20",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.vfb.id,
        },
        guest: {
          teamId: fwcTeams.dsc.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T17:25:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 21",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.koe.id,
        },
        guest: {
          teamId: fwcTeams.fcu.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T17:40:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 22",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.b04.id,
        },
        guest: {
          teamId: fwcTeams.bsc.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T17:55:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 23",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.bmg.id,
        },
        guest: {
          teamId: fwcTeams.hsv.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T18:10:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 24",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.s04.id,
        },
        guest: {
          teamId: fwcTeams.svw.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-18T18:25:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 25",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.koe.id,
        },
        guest: {
          teamId: fwcTeams.bvb.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T08:30:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 26",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.s04.id,
        },
        guest: {
          teamId: fwcTeams.mka.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T08:45:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 27",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.b04.id,
        },
        guest: {
          teamId: fwcTeams.dsc.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T09:00:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 28",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.bmg.id,
        },
        guest: {
          teamId: fwcTeams.boc.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T09:15:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 29",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.fcu.id,
        },
        guest: {
          teamId: fwcTeams.m05.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T09:30:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 30",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.hsv.id,
        },
        guest: {
          teamId: fwcTeams.rbl.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T09:45:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 31",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.bsc.id,
        },
        guest: {
          teamId: fwcTeams.vfb.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T10:00:00").toISO(),
    },
    {
      id: uuid(),
      groupId: groupVorrunde.groupId,
      matchdayId: matchdays.vorrunde.matchdayId,
      name: "Spiel 32",
      competitionId: fwc.id,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          teamId: fwcTeams.svw.id,
        },
        guest: {
          teamId: fwcTeams.fcb.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T10:15:00").toISO(),
    },
  ];

  const createGamesVorrunde = gamesVorrunde.map((g) => planGame(g));
  await Promise.all(createGamesVorrunde);

  const gamesAchtelfinale: Record<
    "g33" | "g34" | "g35" | "g36" | "g37" | "g38" | "g39" | "g40",
    PlanGame
  > = {
    g33: {
      id: uuid(),
      name: "Spiel 33",
      competitionId: fwc.id,
      matchdayId: matchdays.achtelfinale.matchdayId,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          groupId: groupVorrunde.groupId,
          position: 8,
        },
        guest: {
          groupId: groupVorrunde.groupId,
          position: 9,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T10:45:00").toISO(),
    },
    g34: {
      id: uuid(),
      name: "Spiel 34",
      competitionId: fwc.id,
      matchdayId: matchdays.achtelfinale.matchdayId,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          groupId: groupVorrunde.groupId,
          position: 7,
        },
        guest: {
          groupId: groupVorrunde.groupId,
          position: 10,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T11:00:00").toISO(),
    },
    g35: {
      id: uuid(),
      name: "Spiel 35",
      competitionId: fwc.id,
      matchdayId: matchdays.achtelfinale.matchdayId,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          groupId: groupVorrunde.groupId,
          position: 6,
        },
        guest: {
          groupId: groupVorrunde.groupId,
          position: 11,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T11:15:00").toISO(),
    },
    g36: {
      id: uuid(),
      name: "Spiel 36",
      competitionId: fwc.id,
      matchdayId: matchdays.achtelfinale.matchdayId,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          groupId: groupVorrunde.groupId,
          position: 5,
        },
        guest: {
          groupId: groupVorrunde.groupId,
          position: 12,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T11:30:00").toISO(),
    },
    g37: {
      id: uuid(),
      name: "Spiel 37",
      competitionId: fwc.id,
      matchdayId: matchdays.achtelfinale.matchdayId,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          groupId: groupVorrunde.groupId,
          position: 1,
        },
        guest: {
          groupId: groupVorrunde.groupId,
          position: 16,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T11:45:00").toISO(),
    },
    g38: {
      id: uuid(),
      name: "Spiel 38",
      competitionId: fwc.id,
      matchdayId: matchdays.achtelfinale.matchdayId,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          groupId: groupVorrunde.groupId,
          position: 2,
        },
        guest: {
          groupId: groupVorrunde.groupId,
          position: 15,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T12:00:00").toISO(),
    },
    g39: {
      id: uuid(),
      name: "Spiel 39",
      competitionId: fwc.id,
      matchdayId: matchdays.achtelfinale.matchdayId,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          groupId: groupVorrunde.groupId,
          position: 3,
        },
        guest: {
          groupId: groupVorrunde.groupId,
          position: 14,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T12:15:00").toISO(),
    },
    g40: {
      id: uuid(),
      name: "Spiel 40",
      competitionId: fwc.id,
      matchdayId: matchdays.achtelfinale.matchdayId,
      duration: 12 * 60,
      plannedTeams: {
        home: {
          groupId: groupVorrunde.groupId,
          position: 4,
        },
        guest: {
          groupId: groupVorrunde.groupId,
          position: 13,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T12:30:00").toISO(),
    },
  };

  const createGamesAchtelfinale = Object.values(gamesAchtelfinale).map((g) =>
    planGame(g),
  );
  await Promise.all(createGamesAchtelfinale);

  const gamesViertelfinale: Record<"g41" | "g42" | "g43" | "g44", PlanGame> = {
    g41: {
      id: uuid(),
      name: "Spiel 41",
      competitionId: fwc.id,
      matchdayId: matchdays.viertelfinale.matchdayId,
      duration: 15 * 60,
      plannedTeams: {
        home: {
          winnerTeam: gamesAchtelfinale.g33.id,
        },
        guest: {
          winnerTeam: gamesAchtelfinale.g37.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T13:00:00").toISO(),
    },
    g42: {
      id: uuid(),
      name: "Spiel 42",
      competitionId: fwc.id,
      matchdayId: matchdays.viertelfinale.matchdayId,
      duration: 15 * 60,
      plannedTeams: {
        home: {
          winnerTeam: gamesAchtelfinale.g34.id,
        },
        guest: {
          winnerTeam: gamesAchtelfinale.g38.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T13:20:00").toISO(),
    },
    g43: {
      id: uuid(),
      name: "Spiel 43",
      competitionId: fwc.id,
      matchdayId: matchdays.viertelfinale.matchdayId,
      duration: 15 * 60,
      plannedTeams: {
        home: {
          winnerTeam: gamesAchtelfinale.g36.id,
        },
        guest: {
          winnerTeam: gamesAchtelfinale.g40.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T13:40:00").toISO(),
    },
    g44: {
      id: uuid(),
      name: "Spiel 44",
      competitionId: fwc.id,
      matchdayId: matchdays.viertelfinale.matchdayId,
      duration: 15 * 60,
      plannedTeams: {
        home: {
          winnerTeam: gamesAchtelfinale.g35.id,
        },
        guest: {
          winnerTeam: gamesAchtelfinale.g39.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T14:00:00").toISO(),
    },
  };
  const createGamesViertelfinale = Object.values(gamesViertelfinale).map((g) =>
    planGame(g),
  );
  await Promise.all(createGamesViertelfinale);

  const gamesHalbfinale: Record<"g45" | "g46", PlanGame> = {
    g45: {
      id: uuid(),
      name: "Spiel 45",
      competitionId: fwc.id,
      matchdayId: matchdays.halbfinale.matchdayId,
      duration: 15 * 60,
      plannedTeams: {
        home: {
          winnerTeam: gamesViertelfinale.g41.id,
        },
        guest: {
          winnerTeam: gamesViertelfinale.g43.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T14:20:00").toISO(),
    },
    g46: {
      id: uuid(),
      name: "Spiel 46",
      competitionId: fwc.id,
      matchdayId: matchdays.halbfinale.matchdayId,
      duration: 15 * 60,
      plannedTeams: {
        home: {
          winnerTeam: gamesViertelfinale.g42.id,
        },
        guest: {
          winnerTeam: gamesViertelfinale.g44.id,
        },
      },
      dateTime: DateTime.fromISO("2025-01-19T14:40:00").toISO(),
    },
  };
  const createGamesHalbfinale = Object.values(gamesHalbfinale).map((g) =>
    planGame(g),
  );
  await Promise.all(createGamesHalbfinale);

  // spiel um platz 3
  await planGame({
    id: uuid(),
    name: "Spiel 47",
    competitionId: fwc.id,
    matchdayId: matchdays.kleinesfinale.matchdayId,
    duration: 15 * 60,
    plannedTeams: {
      home: {
        loserTeam: gamesHalbfinale.g45.id,
      },
      guest: {
        loserTeam: gamesHalbfinale.g46.id,
      },
    },
    dateTime: DateTime.fromISO("2025-01-19T15:20:00").toISO(),
  });

  // finale
  await planGame({
    id: uuid(),
    name: "Spiel 48",
    competitionId: fwc.id,
    matchdayId: matchdays.finale.matchdayId,
    duration: 15 * 60,
    plannedTeams: {
      home: {
        winnerTeam: gamesHalbfinale.g45.id,
      },
      guest: {
        winnerTeam: gamesHalbfinale.g46.id,
      },
    },
    dateTime: DateTime.fromISO("2025-01-19T15:45:00").toISO(),
  });

  // azubi cup
  const azubicup: CreateTurnier = {
    id: "1d0e5346-92ff-4d98-bcd3-7f3ada65e307",
    name: "HARTING Azubi-Cup 2025",
    start: DateTime.fromISO("2025-01-18").toISO(),
    end: DateTime.fromISO("2025-01-18").toISO(),
    location: "Merkur-Arena Lübbecke",
  };
  await createCompetition(azubicup);

  await sleep(5);

  const azubicupMatchday: CreateMatchday = {
    id: azubicup.id,
    matchdayId: uuid(),
    name: "Finale",
  };
  await createCompetitionMatchday(azubicupMatchday);

  const azubicupTeams: Record<"mer" | "hkr", CreateTeam> = {
    mer: {
      id: uuid(),
      name: "MERKUR.COM AG",
      shortName: "MER",
      primaryColor: "#022652",
      sponsor: {
        name: "MERKUR.COM AG",
        url: "https://www.merkur.com",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/azubicup.jpg",
      },
    },
    hkr: {
      id: uuid(),
      name: "Häcker GmbH & Co. KG",
      shortName: "HKR",
      primaryColor: "#000000",
      sponsor: {
        name: "Häcker GmbH & Co. KG",
        url: "https://www.haecker-kuechen.com/",
        ledwallFilePath:
          "https://app-assets.freewaycup.de/ledwall/sponsors/$widthx$height/azubicup.jpg",
      },
    },
  };

  const createAzubicupTeams = Object.values(azubicupTeams).map(async (t) => {
    await createTeam(t);
    await enrollTeam({
      id: azubicup.id,
      teamId: t.id,
    });
  });
  await Promise.all(createAzubicupTeams);

  // finale
  await planGame({
    id: uuid(),
    name: "Finale",
    competitionId: azubicup.id,
    matchdayId: azubicupMatchday.matchdayId,
    duration: 15 * 60,
    plannedTeams: {
      home: {
        teamId: azubicupTeams.mer.id,
      },
      guest: {
        teamId: azubicupTeams.hkr.id,
      },
    },
    dateTime: DateTime.fromISO("2025-01-18T16:15:00").toISO(),
  });

  const createAllPlayers = players.map((p) => {
    return createPlayer({
      id: uuid(),
      firstName: p.firstName,
      lastName: p.lastName,
      isGoalkeeper: p.isGoalkeeper,
      number: p.number,
      teamId:
        fwcTeams[p.teamShortName]?.id ??
        azubicupTeams[p.teamShortName]?.id ??
        "",
      birthday: `${p.birthday}T00:00:00+01:00`,
    });
  });

  await Promise.all(createAllPlayers);
};
