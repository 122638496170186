import { FC, useEffect } from "react";
import { Page } from "../../../Components/Page";
import {
  Center,
  Loader,
  Text,
  Title,
  Button,
  Alert,
  Grid,
  ScrollArea,
  Divider,
  Stack,
  Space,
} from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import {
  gameFreezeRegularTime,
  gamePauseClock,
  gameResumeClock,
  gameStart,
  gameTrackShootOutShot,
  startCountdown,
  troetHorn,
} from "../../../openapi/api-client";
import { TimeButton } from "./Components/TimeButton";
import { GoalButton } from "./Components/GoalButton";
import { GameTimeline } from "./Components/GameTimeline";
import { useNavigate } from "react-router-dom";
import { TeamName } from "../../../Components/TeamName";
import { useViewportSize } from "@mantine/hooks";
import {
  IconCheck,
  IconHourglassHigh,
  IconVolume,
  IconX,
} from "@tabler/icons-react";
import { TimePenaltyButton } from "./Components/TimePenaltyButton";
import { TimePenaltyCountdown } from "./Components/TimePenaltyCountdown";
import { TeamLogo } from "../../../Components/TeamLogo";
import { useGameTime } from "../../../hooks/useGameTime";
import { LinksForm } from "./Components/LinksForm";
import { useGame } from "../../../hooks/useGame.ts";

interface GameDetailProps {
  gameId: string;
}

export const GameDetail: FC<GameDetailProps> = (props) => {
  const { gameId } = props;

  const game = useGame(gameId);
  const navigate = useNavigate();
  const viewport = useViewportSize();

  const time = useGameTime(gameId) ?? 0;

  const startGameMutation = useMutation({ mutationFn: gameStart });
  const resumeGameMutation = useMutation({
    mutationFn: async (arg: { id: string }) => {
      try {
        return await gameResumeClock(arg);
      } catch (e) {
        return; // todo: do not ignore ALL errors
      }
    },
  });
  const pauseGameMutation = useMutation({
    mutationFn: async (arg: { id: string }) => {
      try {
        return await gamePauseClock(arg);
      } catch (e) {
        return; // todo: do not ignore ALL errors
      }
    },
  });
  const freezeRegularTimeMutation = useMutation({
    mutationFn: gameFreezeRegularTime,
  });
  const trackShootOutShotMutation = useMutation({
    mutationFn: gameTrackShootOutShot,
  });

  useEffect(() => {
    if (
      game.isSuccess &&
      (!game.data.actualTeams.homeTeamId || !game.data.actualTeams.guestTeamId)
    ) {
      console.log("navigate");
      navigate(
        `/control/competitions/${game.data.competitionId}/games/${game.data.id}/set-actual-teams`,
      );
    }
  }, [game.data, game.isSuccess]);

  if (!gameId || !game.isSuccess) {
    return <Loader />;
  }

  if (!game.data.actualTeams.homeTeamId || !game.data.actualTeams.guestTeamId) {
    return <>Actual Teams not set</>;
  }

  return (
    <Page>
      <Grid gutter="xl">
        <Grid.Col span={8}>
          <Title ta="center" mb="md">
            <TeamName teamId={game.data.actualTeams.homeTeamId} />
            <> vs. </>
            <TeamName teamId={game.data.actualTeams.guestTeamId} />
          </Title>

          {game.data.result && (
            <Alert color="green" title="Spiel beendet" variant="filled" mb="lg">
              An diesem Spiel können keine Änderungen mehr vorgenommen werden.
            </Alert>
          )}

          {!game.data.shootOutStarted && (
            <>
              <Grid mb="xl" align="center">
                <Grid.Col span={2}>
                  <TeamLogo teamId={game.data.actualTeams.homeTeamId} />
                </Grid.Col>
                <Grid.Col span={8}>
                  <TimeButton
                    time={time}
                    regieReady={game.data.livestreamRegieReady}
                    running={game.data.clockRunning}
                    started={game.data.started}
                    freezed={game.data.regularTimeFreezed}
                    regularTimeOver={game.data.regularTimeOver}
                    start={async () =>
                      await startGameMutation.mutateAsync({ id: gameId })
                    }
                    pause={async () =>
                      await pauseGameMutation.mutateAsync({ id: gameId })
                    }
                    resume={async () =>
                      await resumeGameMutation.mutateAsync({ id: gameId })
                    }
                    freezeRegularTime={async () =>
                      await freezeRegularTimeMutation.mutateAsync({
                        id: gameId,
                      })
                    }
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <TeamLogo teamId={game.data.actualTeams.guestTeamId} />
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={2}>
                  <Stack>
                    <TimePenaltyButton
                      teamId={game.data.actualTeams.homeTeamId}
                      gameId={game.data.id}
                      time={time}
                      disabled={
                        !game.data.started || game.data.regularTimeFreezed
                      }
                    />
                    {game.data.timePenalties
                      .filter(
                        (tp) => tp.teamId == game.data.actualTeams.homeTeamId,
                      )
                      .filter((tp) => tp.time - tp.duration < time)
                      .filter((tp) => !tp.finishedByGoalId)
                      .map((tp) => (
                        <div key={tp.timePenaltyId}>
                          <TimePenaltyCountdown time={time} timePenalty={tp} />
                        </div>
                      ))}
                  </Stack>
                </Grid.Col>
                <Grid.Col span={4}>
                  <GoalButton
                    teamId={game.data.actualTeams.homeTeamId}
                    gameId={game.data.id}
                    time={time}
                    goals={game.data.score.home}
                    disabled={
                      !game.data.started || game.data.regularTimeFreezed
                    }
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <GoalButton
                    teamId={game.data.actualTeams.guestTeamId}
                    gameId={game.data.id}
                    time={time}
                    goals={game.data.score.guest}
                    disabled={
                      !game.data.started || game.data.regularTimeFreezed
                    }
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <Stack>
                    <TimePenaltyButton
                      teamId={game.data.actualTeams.guestTeamId}
                      gameId={game.data.id}
                      time={time}
                      disabled={
                        !game.data.started || game.data.regularTimeFreezed
                      }
                    />
                    {game.data.timePenalties
                      .filter(
                        (tp) => tp.teamId == game.data.actualTeams.guestTeamId,
                      )
                      .filter((tp) => tp.time - tp.duration < time)
                      .filter((tp) => !tp.finishedByGoalId)
                      .map((tp) => (
                        <div key={tp.timePenaltyId}>
                          <TimePenaltyCountdown time={time} timePenalty={tp} />
                          <br />
                        </div>
                      ))}
                  </Stack>
                </Grid.Col>
              </Grid>
              {!game.data.result && (
                <Grid mt="xl">
                  <Grid.Col span={2} />
                  <Grid.Col span={6}>
                    <Button.Group>
                      <Button
                        leftSection={<IconVolume />}
                        size="xl"
                        color="yellow"
                        fullWidth
                        onClick={() => troetHorn({})}
                      >
                        Tröten
                      </Button>
                      <Button
                        leftSection={<IconHourglassHigh />}
                        size="xl"
                        color="fwcBlue"
                        fullWidth
                        onClick={() => startCountdown({})}
                      >
                        Countdown
                      </Button>
                    </Button.Group>
                  </Grid.Col>
                </Grid>
              )}
            </>
          )}

          {game.data.shootOutStarted && (
            <>
              <Center>
                <Title order={2}>9-m-Schießen</Title>
              </Center>
              <Center mb="xl">
                <Text>Durchgänge: {game.data.shootOutShotsNumber}</Text>
              </Center>
              <Grid>
                <Grid.Col span={2}>
                  <Button
                    disabled={!!game.data.result}
                    onClick={() =>
                      trackShootOutShotMutation.mutateAsync({
                        id: game.data.id,
                        teamId: game.data.actualTeams.homeTeamId ?? "",
                        success: true,
                      })
                    }
                    color="green"
                    leftSection={<IconCheck />}
                    fullWidth
                    size="xl"
                  >
                    Getroffen
                  </Button>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Button
                    disabled={!!game.data.result}
                    onClick={() =>
                      trackShootOutShotMutation.mutateAsync({
                        id: game.data.id,
                        teamId: game.data.actualTeams.homeTeamId ?? "",
                        success: false,
                      })
                    }
                    color="red"
                    leftSection={<IconX />}
                    fullWidth
                    size="xl"
                  >
                    Verfehlt
                  </Button>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text ta="center" size={"36px"}>
                    {game.data.shootOutRounds ? (
                      <>
                        {
                          game.data.shootOutRounds.filter(
                            (r) => r.home && r.home.success,
                          ).length
                        }
                        :
                        {
                          game.data.shootOutRounds.filter(
                            (r) => r.guest && r.guest.success,
                          ).length
                        }
                      </>
                    ) : (
                      <>0:0</>
                    )}
                  </Text>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Button
                    disabled={!!game.data.result}
                    onClick={() =>
                      trackShootOutShotMutation.mutateAsync({
                        id: game.data.id,
                        teamId: game.data.actualTeams.guestTeamId ?? "",
                        success: true,
                      })
                    }
                    color="green"
                    leftSection={<IconCheck />}
                    fullWidth
                    size="xl"
                  >
                    Getroffen
                  </Button>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Button
                    disabled={!!game.data.result}
                    onClick={() =>
                      trackShootOutShotMutation.mutateAsync({
                        id: game.data.id,
                        teamId: game.data.actualTeams.guestTeamId ?? "",
                        success: false,
                      })
                    }
                    color="red"
                    leftSection={<IconX />}
                    fullWidth
                    size="xl"
                  >
                    Verfehlt
                  </Button>
                </Grid.Col>
              </Grid>

              <Divider my="xl" />

              <div style={{ fontSize: 40, textAlign: "center" }}>
                {game.data.shootOutRounds?.map((r) => (
                  <>
                    {r.home ? (
                      <>
                        {r.home.success ? <>getroffen</> : <>nicht getroffen</>}
                      </>
                    ) : (
                      <>-</>
                    )}
                    <> : </>
                    {r.guest ? (
                      <>
                        {r.guest.success ? (
                          <>getroffen</>
                        ) : (
                          <>nicht getroffen</>
                        )}
                      </>
                    ) : (
                      <>-</>
                    )}
                    <br />
                  </>
                ))}
              </div>
            </>
          )}
          {game.data.result && (
            <>
              <Space h="xl" />
              <LinksForm gameId={gameId} />
            </>
          )}
        </Grid.Col>

        <Grid.Col span={4}>
          <Title order={2} mb="xl">
            Spielverlauf
          </Title>
          <ScrollArea style={{ height: viewport.height - 150 }}>
            <GameTimeline gameId={gameId} />
          </ScrollArea>
        </Grid.Col>
      </Grid>
    </Page>
  );
};
