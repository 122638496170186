import { FC } from "react";
import { AspectRatio } from "@mantine/core";
import { useNetwork, useOs } from "@mantine/hooks";

export const Stream: FC = () => {
  const network = useNetwork();

  const autostart = network.type != "cellular" && !network.saveData;

  const os = useOs();

  return (
    <div style={{ textAlign: "right" }}>
      <AspectRatio ratio={16 / 9}>
        <iframe
          title="Livestream"
          style={{ border: "none", width: "100%", height: "100%" }}
          allowFullScreen
          x-webkit-airplay="allow"
          src={`https://start.video-stream-hosting.de/player.html?serverapp=freewaycup-live&streamname=livestream.smil&playsinline=1&timelinecolor=285aa1&bgimage=https://app.freewaycup.de/img/screenshot-stream.jpg&autostart=${
            autostart ? "1&mute=1" : "0"
          }`}
        />
      </AspectRatio>
      {os == "ios" && (
        <a href="https://start.video-stream-hosting.de/player.html?serverapp=freewaycup-live&streamname=livestream.smil&timelinecolor=285aa1&autostart=1&mute=1">
          Vollbildmodus
        </a>
      )}
    </div>
  );
};
