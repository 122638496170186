import { FC, useEffect } from "react";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import {
  gameSetActualTeams,
  getGame,
  getLeaderboard,
  listTeams,
} from "../../../openapi/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Page } from "../../../Components/Page";
import { Button, Group, Loader, Select, Stack } from "@mantine/core";
import { ResolvedTeamReference } from "../../../Components/ResolvedTeamReference";
import { components } from "../../../openapi/generated-types";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useGame } from "../../../hooks/useGame.ts";
import { useCompetition } from "../../../hooks/useCompetition.ts";

// todo: is this a hook?
const teamRefToTeamId = async (
  teamRef: components["schemas"]["TeamReference"],
  competitionId: string,
): Promise<string | undefined> => {
  if (teamRef.teamId) {
    return teamRef.teamId;
  }

  if (teamRef.winnerTeam) {
    const response = await getGame({
      gameId: teamRef.winnerTeam,
    });
    if (response.data.score.home > response.data.score.guest) {
      return response.data.actualTeams.homeTeamId;
    }
    if (response.data.score.guest > response.data.score.home) {
      return response.data.actualTeams.guestTeamId;
    }
  }

  if (teamRef.loserTeam) {
    const response = await getGame({
      gameId: teamRef.loserTeam,
    });
    if (response.data.score.home > response.data.score.guest) {
      return response.data.actualTeams.guestTeamId;
    }
    if (response.data.score.guest > response.data.score.home) {
      return response.data.actualTeams.homeTeamId;
    }
  }

  if (teamRef.groupId && teamRef.position) {
    const leaderboard = await getLeaderboard({
      groupId: teamRef.groupId,
      competitionId,
    });

    return leaderboard.data.leaderboard[teamRef.position - 1]?.teamId;
  }
};

export const SetActualTeams: FC = () => {
  const { gameId } = useRequiredParams(["gameId"]);
  const navigate = useNavigate();

  const game = useGame(gameId);

  const teams = useQuery({
    queryKey: ["teams"],
    queryFn: async () => {
      const response = await listTeams({}); // todo: getTeamsByCompetition
      return response.data;
    },
  });

  const form = useForm<{
    homeTeamId: string;
    guestTeamId: string;
  }>({
    initialValues: {
      homeTeamId: "",
      guestTeamId: "",
    },
  });

  const recommendedHomeTeamId = useQuery({
    queryKey: [game.data?.plannedTeams.home],
    queryFn: async () => {
      if (!game.isSuccess) {
        throw Error("request should not be enabled");
      }

      if (game.data.actualTeams.homeTeamId) {
        return game.data.actualTeams.homeTeamId;
      }

      return (
        (await teamRefToTeamId(
          game.data.plannedTeams.home,
          game.data.competitionId,
        )) ?? null
      );
    },
    enabled: game.isSuccess,
  });

  useEffect(() => {
    if (!recommendedHomeTeamId.isSuccess) {
      return;
    }

    form.setFieldValue("homeTeamId", recommendedHomeTeamId.data ?? "");
  }, [recommendedHomeTeamId.data, recommendedHomeTeamId.isSuccess]);

  const recommendedGuestTeamId = useQuery({
    queryKey: [game.data?.plannedTeams.guest],
    queryFn: async () => {
      if (!game.isSuccess) {
        throw Error("request should not be enabled");
      }

      if (game.data.actualTeams.guestTeamId) {
        return game.data.actualTeams.guestTeamId;
      }

      return (
        (await teamRefToTeamId(
          game.data.plannedTeams.guest,
          game.data.competitionId,
        )) ?? null
      );
    },
    enabled: game.isSuccess,
  });

  useEffect(() => {
    if (!recommendedGuestTeamId.isSuccess) {
      return;
    }

    form.setFieldValue("guestTeamId", recommendedGuestTeamId.data ?? "");
  }, [recommendedGuestTeamId.data, recommendedGuestTeamId.isSuccess]);

  const competition = useCompetition(game.data?.competitionId);

  useEffect(() => {
    if (
      game.isSuccess &&
      game.data.actualTeams.homeTeamId &&
      game.data.actualTeams.guestTeamId
    ) {
      navigate(`..`, { relative: "path" });
    }
  }, [game.data, game.isSuccess]);

  const setActualTeamsMutation = useMutation({
    mutationFn: gameSetActualTeams,
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (!game.isSuccess) {
      throw new Error("game not loaded");
    }

    const homeTeamId = values.homeTeamId == "" ? undefined : values.homeTeamId;
    const guestTeamId =
      values.guestTeamId == "" ? undefined : values.guestTeamId;

    await setActualTeamsMutation.mutateAsync({
      id: game.data.id,
      homeTeamId,
      guestTeamId,
    });

    if (homeTeamId && guestTeamId) {
      navigate(`..`, { relative: "path" });
      return;
    }

    navigate(`../../..`, { relative: "path" });
  });

  if (
    !game.isSuccess ||
    !competition.isSuccess ||
    !teams.isSuccess ||
    !recommendedHomeTeamId.isSuccess ||
    !recommendedGuestTeamId.isSuccess
  ) {
    return <Loader />;
  }

  const filteredTeams = teams.data.teams.filter((t) =>
    competition.data.teamIds.includes(t.id),
  );

  return (
    <Page title={`Teams für ${game.data.name} festlegen`}>
      <form onSubmit={onSubmit}>
        <Stack>
          <Select
            label="Heim"
            placeholder="Team auswählen"
            readOnly={!!game.data.actualTeams.homeTeamId}
            clearable
            description={
              <ResolvedTeamReference
                teamReference={game.data.plannedTeams.home}
                competitionId={competition.data.id}
              />
            }
            searchable
            nothingFoundMessage="kein passendes Team gefunden"
            data={filteredTeams.map((t) => ({
              label: t.name,
              value: t.id,
            }))}
            {...form.getInputProps("homeTeamId")}
          />
          <Select
            label="Gast"
            placeholder="Team auswählen"
            readOnly={!!game.data.actualTeams.guestTeamId}
            clearable
            description={
              <ResolvedTeamReference
                teamReference={game.data.plannedTeams.guest}
                competitionId={competition.data.id}
              />
            }
            searchable
            nothingFoundMessage="kein passendes Team gefunden"
            data={filteredTeams.map((t) => ({
              label: t.name,
              value: t.id,
            }))}
            {...form.getInputProps("guestTeamId")}
          />

          <Group mt="md">
            <Button type="submit">Teams festlegen</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
