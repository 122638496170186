// generated with https://csvjson.com/csv2json from combined spreadsheet

import { operations } from "./openapi/generated-types.ts";

type CreatePlayer =
  operations["player.write.create"]["requestBody"]["content"]["application/json"];

export const players: (Omit<CreatePlayer, "teamId" | "id"> & {
  teamShortName: string;
})[] = [
  {
    number: 1,
    firstName: "Neven",
    lastName: "John",
    birthday: "2009-05-05",
    isGoalkeeper: true,
    teamShortName: "koe",
  },
  {
    number: 2,
    firstName: "Jannis",
    lastName: "Sobkowiak",
    birthday: "2009-01-30",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 15,
    firstName: "Milan",
    lastName: "Nikolic",
    birthday: "2009-01-20",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 9,
    firstName: "Elfried",
    lastName: "Assiobo-Tipoh",
    birthday: "2009-12-14",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 13,
    firstName: "Jan",
    lastName: "Colles",
    birthday: "2009-05-18",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 7,
    firstName: "Eymen",
    lastName: "Erdogan",
    birthday: "2009-06-19",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 10,
    firstName: "Emanuele",
    lastName: "Esposito",
    birthday: "2009-09-20",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 5,
    firstName: "Eren",
    lastName: "Basaran",
    birthday: "2009-03-19",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 3,
    firstName: "Ibrahima",
    lastName: "Balde",
    birthday: "2009-06-16",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 14,
    firstName: "Leonhard",
    lastName: "Ive",
    birthday: "2009-03-02",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 8,
    firstName: "Kimi-Ian",
    lastName: "Majunk",
    birthday: "2009-06-13",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  {
    number: 6,
    firstName: "Merdi",
    lastName: "Kangela",
    birthday: "2009-06-13",
    isGoalkeeper: false,
    teamShortName: "koe",
  },
  // mka
  {
    number: 1,
    firstName: "Hannes Bo",
    lastName: "Sensmeyer",
    birthday: "2008-02-18",
    isGoalkeeper: true,
    teamShortName: "mka",
  },
  {
    number: 6,
    firstName: "Lasse",
    lastName: "Riechmann",
    birthday: "2008-02-12",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 13,
    firstName: "Tobias",
    lastName: "Frodermann",
    birthday: "2008-03-23",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 15,
    firstName: "Seyyid",
    lastName: "Duman",
    birthday: "2008-07-10",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 8,
    firstName: "Tyler",
    lastName: "Sork",
    birthday: "2008-08-01",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 14,
    firstName: "Adnan",
    lastName: "Aziraj",
    birthday: "2009-05-26",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 9,
    firstName: "Vincent",
    lastName: "Schulz",
    birthday: "2008-02-13",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 7,
    firstName: "David",
    lastName: "Peters",
    birthday: "2008-08-24",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 11,
    firstName: "Xavier",
    lastName: "Remedios Carvalho",
    birthday: "2008-09-13",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 10,
    firstName: "Neal",
    lastName: "Mohme",
    birthday: "2008-05-22",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 5,
    firstName: "Lennard",
    lastName: "Dettmann",
    birthday: "2008-12-05",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  {
    number: 3,
    firstName: "Thore",
    lastName: "Schubert",
    birthday: "2008-02-10",
    isGoalkeeper: false,
    teamShortName: "mka",
  },
  // bvb
  {
    number: 11,
    firstName: "Ahmad",
    lastName: "Najdi",
    birthday: "2009-02-04",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 8,
    firstName: "Marwan",
    lastName: "Mirza",
    birthday: "2009-09-01",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 3,
    firstName: "Semih",
    lastName: "Kaymak",
    birthday: "2009-05-30",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 7,
    firstName: "Fadi",
    lastName: "Zarqelain",
    birthday: "2009-01-03",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 20,
    firstName: "Luis",
    lastName: "Alaze",
    birthday: "2009-05-05",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 13,
    firstName: "Christos",
    lastName: "Kostoglou",
    birthday: "2009-06-27",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 4,
    firstName: "Lenni",
    lastName: "Strößner",
    birthday: "2009-01-15",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 10,
    firstName: "Johann",
    lastName: "Jaensch",
    birthday: "2009-02-04",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 9,
    firstName: "Desmond",
    lastName: "Ndlovu",
    birthday: "2009-05-28",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 15,
    firstName: "Levin",
    lastName: "Vogt",
    birthday: "2009-02-24",
    isGoalkeeper: false,
    teamShortName: "bvb",
  },
  {
    number: 12,
    firstName: "Paul",
    lastName: "Hannig",
    birthday: "2009-01-31",
    isGoalkeeper: true,
    teamShortName: "bvb",
  },
  {
    number: 22,
    firstName: "Julian",
    lastName: "Droste",
    birthday: "2009-08-27",
    isGoalkeeper: true,
    teamShortName: "bvb",
  },
  // bmg
  {
    number: 1,
    firstName: "Joshua",
    lastName: "Thimm",
    birthday: "2009-10-31",
    isGoalkeeper: true,
    teamShortName: "bmg",
  },
  {
    number: 2,
    firstName: "Jesse",
    lastName: "Ernst",
    birthday: "2009-05-09",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 3,
    firstName: "Emilyo",
    lastName: "Bayekula",
    birthday: "2009-09-04",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 6,
    firstName: "Noel",
    lastName: "Trostjanezki",
    birthday: "2009-01-04",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 7,
    firstName: "Peniel",
    lastName: "Poba",
    birthday: "2009-02-04",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 8,
    firstName: "Malik",
    lastName: "Simsek",
    birthday: "2009-07-26",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 9,
    firstName: "Ruben",
    lastName: "Toboko Botey",
    birthday: "2009-09-14",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 10,
    firstName: "Edin",
    lastName: "Biber",
    birthday: "2009-03-07",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 17,
    firstName: "Rayan",
    lastName: "Khadraoui",
    birthday: "2009-01-28",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 18,
    firstName: "Kas",
    lastName: "Hendrix",
    birthday: "2009-01-29",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 19,
    firstName: "Diamant",
    lastName: "Rushiti",
    birthday: "2009-09-16",
    isGoalkeeper: false,
    teamShortName: "bmg",
  },
  {
    number: 30,
    firstName: "Abdel",
    lastName: "Amri",
    birthday: "2009-02-18",
    isGoalkeeper: true,
    teamShortName: "bmg",
  },
  // svw
  {
    number: 2,
    firstName: "Bennett",
    lastName: "Warschke",
    birthday: "2009-02-05",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 20,
    firstName: "Vincent",
    lastName: "Müller",
    birthday: "2009-07-20",
    isGoalkeeper: true,
    teamShortName: "svw",
  },
  {
    number: 7,
    firstName: "Damian",
    lastName: "Lepper",
    birthday: "2009-12-15",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 1,
    firstName: "Win",
    lastName: "Hüneke",
    birthday: "2009-03-02",
    isGoalkeeper: true,
    teamShortName: "svw",
  },
  {
    number: 5,
    firstName: "Ole",
    lastName: "Hilgendorf",
    birthday: "2009-04-17",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 10,
    firstName: "Elias",
    lastName: "Frey",
    birthday: "2009-02-09",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 11,
    firstName: "Tomas",
    lastName: "Collevecchio",
    birthday: "2009-03-30",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 8,
    firstName: "Devin",
    lastName: "Bischel",
    birthday: "2009-03-14",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 17,
    firstName: "Linus",
    lastName: "Brodtmann",
    birthday: "2009-01-14",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 13,
    firstName: "Maximilian",
    lastName: "Garstka",
    birthday: "2009-06-05",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 18,
    firstName: "Radoncic",
    lastName: "Haris",
    birthday: "2009-05-08",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  {
    number: 4,
    firstName: "Julian",
    lastName: "Luft",
    birthday: "2009-05-02",
    isGoalkeeper: false,
    teamShortName: "svw",
  },
  // s04
  {
    number: 1,
    firstName: "Finn",
    lastName: "Schulz",
    birthday: "2009-06-08",
    isGoalkeeper: true,
    teamShortName: "s04",
  },
  {
    number: 2,
    firstName: "Amir",
    lastName: "El-Abdouni",
    birthday: "2009-01-05",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 3,
    firstName: "Karol",
    lastName: "Golba",
    birthday: "2009-01-10",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 4,
    firstName: "Laurin",
    lastName: "Zhushi",
    birthday: "2009-10-11",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 5,
    firstName: "Max",
    lastName: "Murawski",
    birthday: "2009-05-27",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 6,
    firstName: "Resat",
    lastName: "Topatan",
    birthday: "2009-06-24",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 7,
    firstName: "Tom",
    lastName: "Schönfelder",
    birthday: "2009-07-07",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 8,
    firstName: "Leard",
    lastName: "Murici",
    birthday: "2009-06-17",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 9,
    firstName: "Mamadou Alpha",
    lastName: "Conde",
    birthday: "2009-09-15",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 10,
    firstName: "Kimon",
    lastName: "Seidel",
    birthday: "2009-07-25",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 11,
    firstName: "Ehsan",
    lastName: "Rezai",
    birthday: "2009-09-07",
    isGoalkeeper: false,
    teamShortName: "s04",
  },
  {
    number: 25,
    firstName: "Lennox",
    lastName: "Richter",
    birthday: "2009-04-14",
    isGoalkeeper: true,
    teamShortName: "s04",
  },
  // b04
  {
    number: 1,
    firstName: "Elias",
    lastName: "Bürger",
    birthday: "2009-02-07",
    isGoalkeeper: true,
    teamShortName: "b04",
  },
  {
    number: 3,
    firstName: "Luan",
    lastName: "Hucke",
    birthday: "2009-03-18",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 8,
    firstName: "Mick",
    lastName: "Aziz Mohammadi",
    birthday: "2009-04-16",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 10,
    firstName: "Alpha",
    lastName: "Barry",
    birthday: "2009-06-15",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 11,
    firstName: "Maksym",
    lastName: "Hololobov",
    birthday: "2009-03-14",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 14,
    firstName: "Marco",
    lastName: "Ascione",
    birthday: "2009-03-09",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 16,
    firstName: "Rayan",
    lastName: "Zidane",
    birthday: "2009-04-14",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 17,
    firstName: "Felix",
    lastName: "Fink",
    birthday: "2009-05-03",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 20,
    firstName: "Ole",
    lastName: "Klaßen",
    birthday: "2009-05-08",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 21,
    firstName: "Yehor",
    lastName: "Koshchii",
    birthday: "2009-01-24",
    isGoalkeeper: false,
    teamShortName: "b04",
  },
  {
    number: 22,
    firstName: "Justus",
    lastName: "Gubbels",
    birthday: "2009-04-17",
    isGoalkeeper: true,
    teamShortName: "b04",
  },
  // dsc
  {
    number: 1,
    firstName: "Ian",
    lastName: "Chamessi",
    birthday: "2009-09-26",
    isGoalkeeper: true,
    teamShortName: "dsc",
  },
  {
    number: 12,
    firstName: "Justus",
    lastName: "Matare",
    birthday: "2009-04-14",
    isGoalkeeper: true,
    teamShortName: "dsc",
  },
  {
    number: 5,
    firstName: "Cristiano",
    lastName: "Carione",
    birthday: "2009-05-08",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  {
    number: 6,
    firstName: "Till",
    lastName: "Wegener",
    birthday: "2009-02-01",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  {
    number: 7,
    firstName: "Fadil",
    lastName: "Zamari",
    birthday: "2009-01-17",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  {
    number: 8,
    firstName: "Kaan",
    lastName: "Düm",
    birthday: "2009-09-14",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  {
    number: 9,
    firstName: "Ben",
    lastName: "Rehage",
    birthday: "2009-01-14",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  {
    number: 10,
    firstName: "Joshua",
    lastName: "Oghie",
    birthday: "2009-11-24",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  {
    number: 16,
    firstName: "Till",
    lastName: "Czekalla",
    birthday: "2009-06-30",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  {
    number: 18,
    firstName: "Rafael",
    lastName: "Rose",
    birthday: "2009-12-18",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  {
    number: 20,
    firstName: "Mateusz",
    lastName: "Kuczkowiak",
    birthday: "2009-01-01",
    isGoalkeeper: false,
    teamShortName: "dsc",
  },
  // hsv
  {
    number: 22,
    firstName: "James",
    lastName: "Stubbs",
    birthday: "2009-01-26",
    isGoalkeeper: true,
    teamShortName: "hsv",
  },
  {
    number: 16,
    firstName: "Maximilian",
    lastName: "Schmid",
    birthday: "2009-04-08",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 5,
    firstName: "Paul",
    lastName: "Johansons",
    birthday: "2009-03-16",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 4,
    firstName: "Tyron",
    lastName: "Conrädel",
    birthday: "2009-09-21",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 18,
    firstName: "Joao",
    lastName: "Balde",
    birthday: "2009-05-04",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 7,
    firstName: "David",
    lastName: "Wourie",
    birthday: "2009-02-09",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 10,
    firstName: "Manuel",
    lastName: "Abbey",
    birthday: "2009-02-12",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 6,
    firstName: "Berk",
    lastName: "Cakallioglu",
    birthday: "2009-04-01",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 21,
    firstName: "Fiete",
    lastName: "Krause",
    birthday: "2009-01-28",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 20,
    firstName: "Thierry",
    lastName: "Sedlatschek",
    birthday: "2009-04-03",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  {
    number: 27,
    firstName: "Alfa",
    lastName: "Monteiro",
    birthday: "2009-11-02",
    isGoalkeeper: false,
    teamShortName: "hsv",
  },
  // rbl
  {
    number: 3,
    firstName: "Janne",
    lastName: "Kühn",
    birthday: "2009-01-20",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 6,
    firstName: "Hannes",
    lastName: "Laspe",
    birthday: "2009-03-29",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 7,
    firstName: "Valentino",
    lastName: "Fritsche",
    birthday: "2009-10-21",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 8,
    firstName: "Kofi",
    lastName: "Walther",
    birthday: "2009-04-10",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 10,
    firstName: "Simon",
    lastName: "Wurm",
    birthday: "2009-03-15",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 11,
    firstName: "Constantin",
    lastName: "Sandkötter",
    birthday: "2009-01-19",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 12,
    firstName: "Karl",
    lastName: "Hamella",
    birthday: "2009-07-18",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 13,
    firstName: "Milan",
    lastName: "Wiegärtner",
    birthday: "2009-05-09",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 15,
    firstName: "Moritz",
    lastName: "Bock",
    birthday: "2009-03-26",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 16,
    firstName: "Niklas",
    lastName: "Heinicke",
    birthday: "2009-05-18",
    isGoalkeeper: false,
    teamShortName: "rbl",
  },
  {
    number: 22,
    firstName: "Elian",
    lastName: "Hähner",
    birthday: "2009-03-25",
    isGoalkeeper: true,
    teamShortName: "rbl",
  },
  // fcu
  {
    number: 1,
    firstName: "Niklas",
    lastName: "Glienke",
    birthday: "2009-12-16",
    isGoalkeeper: true,
    teamShortName: "fcu",
  },
  {
    number: 20,
    firstName: "Carlos",
    lastName: "Kramer",
    birthday: "2009-10-27",
    isGoalkeeper: false,
    teamShortName: "fcu",
  },
  {
    number: 11,
    firstName: "Leo",
    lastName: "Sorg",
    birthday: "2010-10-15",
    isGoalkeeper: false,
    teamShortName: "fcu",
  },
  {
    number: 5,
    firstName: "Mert",
    lastName: "Badem",
    birthday: "2009-01-19",
    isGoalkeeper: false,
    teamShortName: "fcu",
  },
  {
    number: 9,
    firstName: "Riyad",
    lastName: "Mouhamed",
    birthday: "2009-03-21",
    isGoalkeeper: false,
    teamShortName: "fcu",
  },
  {
    number: 13,
    firstName: "Mark",
    lastName: "Schippeinz",
    birthday: "2009-09-28",
    isGoalkeeper: false,
    teamShortName: "fcu",
  },
  {
    number: 6,
    firstName: "Niklas",
    lastName: "Zunk",
    birthday: "2009-01-08",
    isGoalkeeper: false,
    teamShortName: "fcu",
  },
  {
    number: 14,
    firstName: "Elias",
    lastName: "Badi",
    birthday: "2009-03-28",
    isGoalkeeper: false,
    teamShortName: "fcu",
  },
  {
    number: 19,
    firstName: "Hermann",
    lastName: "Princz",
    birthday: "2009-09-25",
    isGoalkeeper: false,
    teamShortName: "fcu",
  },
  // m05
  {
    number: 1,
    firstName: "Ilyas",
    lastName: "Hamidan",
    birthday: "2009-03-30",
    isGoalkeeper: true,
    teamShortName: "m05",
  },
  {
    number: 3,
    firstName: "Marvin",
    lastName: "Asante",
    birthday: "2009-08-03",
    isGoalkeeper: false,
    teamShortName: "m05",
  },
  {
    number: 4,
    firstName: "David",
    lastName: "Osei",
    birthday: "2009-01-25",
    isGoalkeeper: false,
    teamShortName: "m05",
  },
  {
    number: 5,
    firstName: "Fynn",
    lastName: "Wombacher",
    birthday: "2009-01-08",
    isGoalkeeper: false,
    teamShortName: "m05",
  },
  {
    number: 6,
    firstName: "Alessio",
    lastName: "Kersten",
    birthday: "2009-07-09",
    isGoalkeeper: false,
    teamShortName: "m05",
  },
  {
    number: 7,
    firstName: "Justin",
    lastName: "Baldwin",
    birthday: "2009-01-14",
    isGoalkeeper: false,
    teamShortName: "m05",
  },
  {
    number: 8,
    firstName: "Fabian",
    lastName: "Rieß",
    birthday: "2009-12-05",
    isGoalkeeper: false,
    teamShortName: "m05",
  },
  {
    number: 9,
    firstName: "Noel",
    lastName: "Gisbrecht",
    birthday: "2009-03-23",
    isGoalkeeper: false,
    teamShortName: "m05",
  },
  {
    number: 10,
    firstName: "Simoan",
    lastName: "Tedla",
    birthday: "2009-01-19",
    isGoalkeeper: false,
    teamShortName: "m05",
  },
  // boc
  {
    number: 18,
    firstName: "Lasse",
    lastName: "Zimmermann",
    birthday: "2009-01-30",
    isGoalkeeper: true,
    teamShortName: "boc",
  },
  {
    number: 1,
    firstName: "Georgios",
    lastName: "Polychronidis",
    birthday: "2009-08-01",
    isGoalkeeper: true,
    teamShortName: "boc",
  },
  {
    number: 5,
    firstName: "Robin",
    lastName: "Ince",
    birthday: "2009-03-11",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 2,
    firstName: "Talha",
    lastName: "Yilmaz",
    birthday: "2009-01-21",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 6,
    firstName: "Yusuf",
    lastName: "Mengenli",
    birthday: "2009-01-10",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 8,
    firstName: "Nail",
    lastName: "Kalan",
    birthday: "2009-03-18",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 13,
    firstName: "Oumar",
    lastName: "Beladel",
    birthday: "2009-01-13",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 12,
    firstName: "Adam",
    lastName: "Benali",
    birthday: "2009-02-11",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 7,
    firstName: "Levin",
    lastName: "Tilinski",
    birthday: "2009-02-21",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 10,
    firstName: "Siyeong",
    lastName: "Yang",
    birthday: "2009-05-12",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 19,
    firstName: "Erik",
    lastName: "Abbel",
    birthday: "2009-07-19",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  {
    number: 17,
    firstName: "André",
    lastName: "Müller",
    birthday: "2009-02-10",
    isGoalkeeper: false,
    teamShortName: "boc",
  },
  // bsc
  {
    number: 23,
    firstName: "Denniz",
    lastName: "Ali",
    birthday: "2009-03-23",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 6,
    firstName: "Lorent",
    lastName: "Aliu",
    birthday: "2009-08-14",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 33,
    firstName: "Marko",
    lastName: "Bajars",
    birthday: "2009-09-12",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 1,
    firstName: "Lennox",
    lastName: "Ballwanz",
    birthday: "2009-05-19",
    isGoalkeeper: true,
    teamShortName: "bsc",
  },
  {
    number: 3,
    firstName: "Jerome",
    lastName: "Diallo",
    birthday: "2009-06-13",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 34,
    firstName: "Mayson",
    lastName: "Grothe",
    birthday: "2009-09-21",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 31,
    firstName: "Daniel",
    lastName: "Mrohs",
    birthday: "2009-04-04",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 7,
    firstName: "Banjamin",
    lastName: "Nkili",
    birthday: "2009-05-22",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 5,
    firstName: "Ibrahim",
    lastName: "Saleh",
    birthday: "2009-01-01",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 13,
    firstName: "Connor",
    lastName: "Sucrow",
    birthday: "2009-07-13",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  {
    number: 15,
    firstName: "Ben",
    lastName: "Van Bernum",
    birthday: "2009-09-18",
    isGoalkeeper: false,
    teamShortName: "bsc",
  },
  // vfb
  {
    number: 1,
    firstName: "Lucas",
    lastName: "Nagel",
    birthday: "2009-03-17",
    isGoalkeeper: true,
    teamShortName: "vfb",
  },
  {
    number: 22,
    firstName: "Bastian",
    lastName: "Sperka",
    birthday: "2009-02-09",
    isGoalkeeper: true,
    teamShortName: "vfb",
  },
  {
    number: 11,
    firstName: "Ali",
    lastName: "Aydin",
    birthday: "2009-08-28",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  {
    number: 4,
    firstName: "Tom",
    lastName: "Brenner",
    birthday: "2009-05-14",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  {
    number: 7,
    firstName: "Konstantinos",
    lastName: "Karangelis",
    birthday: "2009-06-07",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  {
    number: 6,
    firstName: "Seva",
    lastName: "Linnikov",
    birthday: "2009-09-20",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  {
    number: 10,
    firstName: "Malik",
    lastName: "Mosrati",
    birthday: "2009-12-12",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  {
    number: 5,
    firstName: "Felix",
    lastName: "Neuenhoeffer",
    birthday: "2009-04-11",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  {
    number: 8,
    firstName: "Moritz",
    lastName: "Schäfer",
    birthday: "2009-06-27",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  {
    number: 9,
    firstName: "Arlind",
    lastName: "Thaqi",
    birthday: "2010-05-12",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  {
    number: 2,
    firstName: "Max",
    lastName: "Ziemer",
    birthday: "2009-08-19",
    isGoalkeeper: false,
    teamShortName: "vfb",
  },
  // fcb
  {
    number: 1,
    firstName: "David",
    lastName: "Podar-Stiube",
    birthday: "2010-05-31",
    isGoalkeeper: true,
    teamShortName: "fcb",
  },
  {
    number: 2,
    firstName: "Adam",
    lastName: "El-Chaar",
    birthday: "2009-04-04",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  {
    number: 3,
    firstName: "Jakob",
    lastName: "Horvath",
    birthday: "2009-06-24",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  {
    number: 4,
    firstName: "Nderim",
    lastName: "Zogaj",
    birthday: "2009-02-27",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  {
    number: 6,
    firstName: "Erblin",
    lastName: "Osmani",
    birthday: "2009-05-19",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  {
    number: 7,
    firstName: "Aviel",
    lastName: "Hentcho",
    birthday: "2009-11-14",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  {
    number: 8,
    firstName: "Simon",
    lastName: "Schaff",
    birthday: "2009-03-03",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  {
    number: 9,
    firstName: "Philipp",
    lastName: "von Taube",
    birthday: "2009-05-04",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  {
    number: 10,
    firstName: "Leeron",
    lastName: "Danioko",
    birthday: "2009-05-16",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  {
    number: 11,
    firstName: "Kuba",
    lastName: "Horoszczak",
    birthday: "2009-08-21",
    isGoalkeeper: false,
    teamShortName: "fcb",
  },
  // hkr
  {
    number: 1,
    firstName: "Melvin",
    lastName: "Finke",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 2,
    firstName: "Niklas",
    lastName: "Grohmann",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 8,
    firstName: "Niklas",
    lastName: "Rudolph",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 21,
    firstName: "Mika",
    lastName: "Tücke",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 6,
    firstName: "Lennart",
    lastName: "Braunsmann",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 19,
    firstName: "Till Noah",
    lastName: "Gröne",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 15,
    firstName: "Gent",
    lastName: "Hajdini",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 3,
    firstName: "Michel",
    lastName: "Schmidt",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 10,
    firstName: "Sascha Aron",
    lastName: "Andres",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 14,
    firstName: "David",
    lastName: "Fedrau",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 16,
    firstName: "Colin",
    lastName: "Lichsenring",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  {
    number: 4,
    firstName: "Ole",
    lastName: "Horstmann",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "hkr",
  },
  // mer
  {
    number: 1,
    firstName: "Max",
    lastName: "Mohrmann",
    birthday: "1970-01-01",
    isGoalkeeper: true,
    teamShortName: "mer",
  },
  {
    number: 4,
    firstName: "Lukas",
    lastName: "Vogelsang",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 5,
    firstName: "Civan",
    lastName: "Ertem",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 6,
    firstName: "Tim",
    lastName: "Kerski",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 7,
    firstName: "Daniel",
    lastName: "Klippenstein",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 8,
    firstName: "Tristan",
    lastName: "Zopf",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 9,
    firstName: "Justin",
    lastName: "Windmann",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 10,
    firstName: "Jeremy",
    lastName: "Reiss",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 11,
    firstName: "Tristan",
    lastName: "Lintelmann",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 13,
    firstName: "Oliver",
    lastName: "Carl",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 14,
    firstName: "Henrik",
    lastName: "Graß",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 16,
    firstName: "Philip",
    lastName: "Plet",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 17,
    firstName: "Christian",
    lastName: "Belkow",
    birthday: "1970-01-01",
    isGoalkeeper: false,
    teamShortName: "mer",
  },
  {
    number: 22,
    firstName: "Kilian",
    lastName: "Berner",
    birthday: "1970-01-01",
    isGoalkeeper: true,
    teamShortName: "mer",
  },
];
