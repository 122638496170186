import React from "react";
import { ThemeIcon, NavLink } from "@mantine/core";

export const NavItem = (props: {
  color: string;
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
}) => {
  const { color, icon, label, onClick } = props;

  return (
    <NavLink
      onClick={onClick}
      label={label}
      leftSection={
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>
      }
    />
  );
};
