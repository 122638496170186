import { FC } from "react";
import { ResolvedTeamReference } from "../../../../Components/ResolvedTeamReference";
import { ActionIcon, Badge, Box, Flex, Table } from "@mantine/core";
import { components } from "../../../../openapi/generated-types";
import { DateTime } from "luxon";
import { IconBrandYoutube, IconPhoto } from "@tabler/icons-react";
import { TeamLogo } from "../../../../Components/TeamLogo";
import { useNavigate } from "react-router-dom";

interface GameListProps {
  games: components["schemas"]["Game"][];
}

export const GameList: FC<GameListProps> = (props) => {
  const { games } = props;

  const navigate = useNavigate();

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th style={{ width: "1%", textAlign: "right" }}>#</Table.Th>
          <Table.Th style={{ width: "1%" }}>Zeit</Table.Th>
          <Table.Th>Paarung</Table.Th>
          <Table.Th style={{ width: "1%" }}></Table.Th>
          <Table.Th style={{ width: "1%" }}></Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {games.map((g) => (
          <Table.Tr key={g.id}>
            <Table.Td>{g.name.replace("Spiel ", "")}</Table.Td>

            <Table.Td style={{ whiteSpace: "nowrap" }}>
              {DateTime.fromISO(g.dateTime).toFormat("ccc., HH:mm")}
            </Table.Td>
            <Table.Td>
              {g.actualTeams.homeTeamId && g.actualTeams.guestTeamId ? (
                <Flex align={"center"}>
                  <Flex
                    pr={5}
                    align="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/teams/${g.actualTeams.homeTeamId}`);
                    }}
                  >
                    <Box w={25}>
                      <TeamLogo height={25} teamId={g.actualTeams.homeTeamId} />
                    </Box>
                  </Flex>
                  &nbsp;–&nbsp;
                  <Flex
                    pl={5}
                    align="center"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/teams/${g.actualTeams.guestTeamId}`);
                    }}
                  >
                    <Box w={25}>
                      <TeamLogo
                        height={25}
                        teamId={g.actualTeams.guestTeamId}
                      />
                    </Box>
                  </Flex>
                </Flex>
              ) : (
                <>
                  {g.actualTeams.homeTeamId ? (
                    <Flex
                      pr={5}
                      align="center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/teams/${g.actualTeams.homeTeamId}`);
                      }}
                    >
                      <Box w={25}>
                        <TeamLogo
                          height={25}
                          teamId={g.actualTeams.homeTeamId}
                        />
                      </Box>
                    </Flex>
                  ) : (
                    <ResolvedTeamReference
                      teamReference={g.plannedTeams.home}
                      competitionId={g.competitionId}
                    />
                  )}
                  {g.actualTeams.homeTeamId || g.actualTeams.guestTeamId ? (
                    <>&nbsp;–&nbsp;</>
                  ) : (
                    <br />
                  )}
                  {g.actualTeams.guestTeamId ? (
                    <Flex
                      pr={5}
                      align="center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/teams/${g.actualTeams.guestTeamId}`);
                      }}
                    >
                      <Box w={25}>
                        <TeamLogo
                          height={25}
                          teamId={g.actualTeams.guestTeamId}
                        />
                      </Box>
                    </Flex>
                  ) : (
                    <ResolvedTeamReference
                      teamReference={g.plannedTeams.guest}
                      competitionId={g.competitionId}
                    />
                  )}
                </>
              )}
            </Table.Td>

            {g.started && (
              <>
                <Table.Td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  {g.score.home}:{g.score.guest}
                  {g.shootOutStarted && (
                    <>
                      {" "}
                      (
                      {g.score.home +
                        g.shootOutRounds.reduce(
                          (previous, round) =>
                            round.home?.success ? previous + 1 : previous,
                          0,
                        )}
                      :
                      {g.score.guest +
                        g.shootOutRounds.reduce(
                          (previous, round) =>
                            round.guest?.success ? previous + 1 : previous,
                          0,
                        )}
                      )
                    </>
                  )}
                </Table.Td>

                {!g.result ? (
                  <Table.Td ta="right">
                    <Badge color="red" w={50}>
                      live
                    </Badge>
                  </Table.Td>
                ) : (
                  <Table.Td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 6,
                      }}
                    >
                      <ActionIcon
                        color="fwcBlue"
                        variant={g.links.youtube ? "light" : "filled"}
                        size="sm"
                        disabled={!g.links.youtube}
                        onClick={
                          g.links.youtube
                            ? () => window.open(g.links.youtube, "_blank")
                            : undefined
                        }
                      >
                        <IconBrandYoutube />
                      </ActionIcon>
                      <ActionIcon
                        color="fwcBlue"
                        size="sm"
                        variant={g.links.gallery ? "light" : "filled"}
                        disabled={!g.links.gallery}
                        onClick={
                          g.links.gallery
                            ? () => window.open(g.links.gallery, "_blank")
                            : undefined
                        }
                      >
                        <IconPhoto />
                      </ActionIcon>
                    </div>
                  </Table.Td>
                )}
              </>
            )}
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};
