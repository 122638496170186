import { FC } from "react";
import { Page } from "../../../Components/Page";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  ActionIcon,
  Avatar,
  Button,
  Group,
  Loader,
  Skeleton,
  Table,
} from "@mantine/core";
import { getTeam, listPlayers } from "../../../openapi/api-client";
import { components } from "../../../openapi/generated-types";
import { DateTime } from "luxon";
import { IconEdit, IconHandStop } from "@tabler/icons-react";

type TableRowProps = {
  player: components["schemas"]["Player"];
};

const TableRow: FC<TableRowProps> = (props) => {
  const { player } = props;
  const navigate = useNavigate();

  const teamData = useQuery({
    queryKey: ["team", { teamId: player.teamId }],
    queryFn: async () => {
      const response = await getTeam({ teamId: player.teamId });
      return response.data;
    },
  });

  const birthday = DateTime.fromISO(player.birthday);
  const age = Math.floor(DateTime.now().diff(birthday).as("year"));

  if (!teamData.isSuccess) {
    return <Skeleton />;
  }

  return (
    <Table.Tr>
      <Table.Td>
        <Avatar>
          {player.firstName.substring(0, 1).toUpperCase()}
          {player.lastName.substring(0, 1).toUpperCase()}
        </Avatar>
      </Table.Td>
      <Table.Td>{player.lastName}</Table.Td>
      <Table.Td>{player.firstName}</Table.Td>
      <Table.Td>
        {birthday.toFormat("dd.MM.yyyy")} ({age})
      </Table.Td>
      <Table.Td>
        <Group>
          {teamData.data.name} (Nr. {player.number})
          <ActionIcon>
            <IconEdit
              size={18}
              onClick={() => navigate(`${player.id}/change-team`)}
            />
          </ActionIcon>
        </Group>
      </Table.Td>
      <Table.Td>{player.isGoalkeeper && <IconHandStop />}</Table.Td>
    </Table.Tr>
  );
};

export const PlayerOverview: FC = () => {
  const navigate = useNavigate();
  const playerData = useQuery({
    queryKey: ["players"],
    queryFn: async () => {
      const response = await listPlayers({});
      return response.data;
    },
  });

  if (!playerData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={"Spieler"}>
      <Button mb="md" onClick={() => navigate("new")}>
        Neuer Spieler
      </Button>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Bild</Table.Th>
            <Table.Th>Nachname</Table.Th>
            <Table.Th>Vorname</Table.Th>
            <Table.Th>Geburtstag (Alter)</Table.Th>
            <Table.Th>Mannschaft</Table.Th>
            <Table.Th>Torwart</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {playerData.data.players.map((p) => (
            <TableRow key={p.id} player={p} />
          ))}
        </Table.Tbody>
      </Table>
    </Page>
  );
};
