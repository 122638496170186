import { FC } from "react";
import { Stack } from "@mantine/core";
import { Topscorer as TopscorerWidget } from "../Widgets/Topscorer";

export const Topscorer: FC = () => {
  return (
    <Stack gap="lg">
      <TopscorerWidget />
    </Stack>
  );
};
