import { FC } from "react";
import { Page } from "../../../Components/Page";
import { listTeams } from "../../../openapi/api-client";
import { useQuery } from "@tanstack/react-query";
import { Button, Loader, Table, Avatar } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { components } from "../../../openapi/generated-types";

type TableRowProps = {
  team: components["schemas"]["Team"];
};

const TableRow: FC<TableRowProps> = (props) => {
  const { team } = props;

  return (
    <Table.Tr>
      <Table.Td>
        <Avatar>{team.shortName}</Avatar>
      </Table.Td>
      <Table.Td>{team.name}</Table.Td>
      <Table.Td>{team.shortName}</Table.Td>
      <Table.Td>{team.sponsor?.name}</Table.Td>
    </Table.Tr>
  );
};

export const TeamOverview: FC = () => {
  const navigate = useNavigate();
  const teamData = useQuery({
    queryKey: ["teams"],
    queryFn: async () => {
      const response = await listTeams({});
      return response.data;
    },
  });

  if (!teamData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={"Mannschaften"}>
      <Button mb="md" onClick={() => navigate("new")}>
        Neue Mannschaft
      </Button>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Logo</Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th>Kurzform</Table.Th>
            <Table.Th>Sponsor</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {teamData.data.teams.map((t) => (
            <TableRow key={t.id} team={t} />
          ))}
        </Table.Tbody>
      </Table>
    </Page>
  );
};
