import { Image } from "@mantine/core";

export const SponsorAd = () => {
  return (
    <Image
      src="https://app-assets.freewaycup.de/sponsorads/anzeige-daryse.jpg"
      alt=""
      onClick={() => window.open("https://www.daryse.com/", "_blank")}
      style={{ cursor: "pointer" }}
    />
  );
};
