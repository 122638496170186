import { FC } from "react";
import { Page } from "../../../Components/Page";
import { Button, Group, Loader, Table } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { components } from "../../../openapi/generated-types";
import { DateTime } from "luxon";
import { useCompetitionList } from "../../../hooks/useCompetitionList.ts";
import { setupFWC2025 } from "../../../fwc-2025.ts";

type TableRowProps = {
  competition: components["schemas"]["Competition"];
};

const TableRow: FC<TableRowProps> = (props) => {
  const { competition } = props;

  return (
    <Table.Tr>
      <Table.Td>
        <Link to={competition.id}>{competition.name}</Link>
      </Table.Td>
      <Table.Td>{competition.location}</Table.Td>
      <Table.Td>
        {DateTime.fromISO(competition.start).toFormat("dd.MM.yyyy")}
      </Table.Td>
      <Table.Td>
        {DateTime.fromISO(competition.end).toFormat("dd.MM.yyyy")}
      </Table.Td>
      <Table.Td>{competition.teamIds.length}</Table.Td>
    </Table.Tr>
  );
};

export const CompetitionOverview: FC = () => {
  const navigate = useNavigate();
  const competitionData = useCompetitionList();

  if (!competitionData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={"Turniere"}>
      <Group>
        <Button mb="md" onClick={() => navigate("new")}>
          Neues Turnier
        </Button>
        <Button mb="md" onClick={() => setupFWC2025()}>
          FWC 2025 generieren
        </Button>
      </Group>
      <Table mt="lg">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Turnier</Table.Th>
            <Table.Th>Ort</Table.Th>
            <Table.Th>Start</Table.Th>
            <Table.Th>Ende</Table.Th>
            <Table.Th>Mannschaften</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {competitionData.data.competitions.map((c) => (
            <TableRow key={c.id} competition={c} />
          ))}
        </Table.Tbody>
      </Table>
    </Page>
  );
};
