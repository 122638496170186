import { FC, useContext } from "react";
import { useDebouncedCurrentGame } from "../../../../hooks/useDebouncedCurrentGame";
import { Box, Flex, Grid, Indicator, Skeleton } from "@mantine/core";
import { TeamName } from "../../../../Components/TeamName";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";
import { TeamLogo } from "../../../../Components/TeamLogo.tsx";
import { useNavigate } from "react-router-dom";

export const CurrentGame: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const currentGame = useDebouncedCurrentGame(competitionId, 5000);

  const navigate = useNavigate();

  if (currentGame.isError) {
    return <></>;
  }

  if (
    !currentGame.isSuccess ||
    !currentGame.data.actualTeams.homeTeamId ||
    !currentGame.data.actualTeams.guestTeamId
  ) {
    return <Skeleton height={76} w="100%"></Skeleton>;
  }

  return (
    <Indicator
      processing={currentGame.data.clockRunning}
      withBorder
      color="green"
      size={15}
      disabled={!currentGame.data.started || !!currentGame.data.result}
      zIndex={99}
    >
      <Grid
        p={"xs"}
        style={{
          textAlign: "center",
          fontFamily: "Chivo",
          backgroundColor: "var(--mantine-color-fwcBlue-9)",
          color: "white",
          fontSize: 34,
          lineHeight: "40px",
          margin: 0,
        }}
        columns={11}
      >
        <Grid.Col span={4} style={{ textAlign: "left" }}>
          <Flex
            gap="xs"
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/teams/${currentGame.data.actualTeams.homeTeamId}`);
            }}
          >
            <Box w={30}>
              <TeamLogo
                height={30}
                teamId={currentGame.data.actualTeams.homeTeamId}
              />
            </Box>
            <TeamName short teamId={currentGame.data.actualTeams.homeTeamId} />
          </Flex>
        </Grid.Col>
        <Grid.Col
          span={3}
          style={{
            textAlign: "center",
            fontFamily: "Chivo Mono",
            fontWeight: 700,
          }}
        >
          {currentGame.data.score.home}–{currentGame.data.score.guest}
        </Grid.Col>
        <Grid.Col span={4} style={{ textAlign: "right" }}>
          <Flex
            gap="xs"
            align="center"
            justify="flex-end"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/teams/${currentGame.data.actualTeams.guestTeamId}`);
            }}
          >
            <TeamName short teamId={currentGame.data.actualTeams.guestTeamId} />
            <Box w={30}>
              <TeamLogo
                height={30}
                teamId={currentGame.data.actualTeams.guestTeamId}
              />
            </Box>
          </Flex>
        </Grid.Col>
      </Grid>
    </Indicator>
  );
};
