import { FC, useContext } from "react";
import { TeamName } from "../../../Components/TeamName";
import { IconBallFootball } from "@tabler/icons-react";
import { useDebouncedCurrentGame } from "../../../hooks/useDebouncedCurrentGame";
import { useShootoutRounds } from "../../../hooks/useShootoutRounds";
import { CompetitionContext } from "../../../CompetitionContext.tsx";
import { useTeam } from "../../../hooks/useTeam.ts";

const failedShot = <IconBallFootball color="red" size={48} />;
const successfullShot = <IconBallFootball color="#00e64d" size={48} />;
const unshotShot = <IconBallFootball color="#e8e8e8" size={48} />;

export const Shootout: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const gameData = useDebouncedCurrentGame(competitionId);

  const homeTeam = useTeam(gameData.data?.actualTeams.homeTeamId);
  const guestTeam = useTeam(gameData.data?.actualTeams.guestTeamId);

  const rounds = useShootoutRounds(gameData.data?.id);

  if (
    !gameData.isSuccess ||
    !homeTeam.isSuccess ||
    !guestTeam.isSuccess ||
    !rounds
  ) {
    return <>asdfasdfasdf</>;
    // todo: remove this
  }

  // todo: missing key here
  const homeTeamShots = rounds.map((r) =>
    r.home ? (r.home.success ? successfullShot : failedShot) : unshotShot,
  );

  const guestTeamShots = rounds.map((r) =>
    r.guest ? (r.guest.success ? successfullShot : failedShot) : unshotShot,
  );

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          display: "inline-block",
          position: "relative",
          top: 900,
          fontFamily: "Chivo",
          fontWeight: 700,
          fontSize: 36,
          backgroundColor: "white",
          color: "black",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              height: 80,
              display: "flex",
            }}
          >
            <div
              style={{
                borderLeftWidth: 12,
                borderLeftStyle: "solid",
                borderLeftColor: homeTeam.data.primaryColor,
                display: "flex",
              }}
            >
              <div
                style={{
                  lineHeight: "80px",
                  paddingLeft: 30,
                  paddingRight: 60,
                }}
              >
                <TeamName teamId={homeTeam.data.id} short />
              </div>
            </div>
            <div style={{ paddingTop: 17, display: "flex", gap: 15 }}>
              {homeTeamShots}
            </div>
          </div>
          <div
            style={{
              height: 80,
              width: 200,
              lineHeight: "80px",
              textAlign: "center",
              backgroundColor: "#194383",
              color: "white",
              marginLeft: 60,
              marginRight: 60,
            }}
          >
            {
              gameData.data.shootOutRounds.filter(
                (r) => r.home && r.home.success,
              ).length
            }
            –
            {
              gameData.data.shootOutRounds.filter(
                (r) => r.guest && r.guest.success,
              ).length
            }
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: 80,
                display: "flex",
              }}
            >
              <div style={{ paddingTop: 17, display: "flex", gap: 15 }}>
                {guestTeamShots}
              </div>
              <div
                style={{
                  borderRightWidth: 12,
                  borderRightStyle: "solid",
                  borderRightColor: guestTeam.data.primaryColor,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    lineHeight: "80px",
                    paddingLeft: 60,
                    paddingRight: 30,
                  }}
                >
                  <TeamName teamId={guestTeam.data.id} short />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
