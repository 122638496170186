import { FC } from "react";
import { Burger, AppShell, Image, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../../../Logo";

type AppHeaderProps = {
  toggleNavbarOpen: (value?: boolean) => void;
  navbarOpen: boolean;
};

export const AppHeader: FC<AppHeaderProps> = (props) => {
  const { toggleNavbarOpen, navbarOpen } = props;
  const navigate = useNavigate();

  return (
    <>
      <AppShell.Header p="md">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Logo
            onClick={() => {
              navigate("/");
              toggleNavbarOpen(false);
            }}
          />

          <div style={{ display: "flex", alignItems: "center" }}>
            <Text size="xs" ta="center" style={{ lineHeight: 1.1 }}>
              App
              <br />
              präsentiert
              <br />
              von
            </Text>
            <div
              style={{
                height: 30,
                width: 100,
                paddingTop: 3,
                marginLeft: 10,
              }}
            >
              <Image
                alt="Daryse"
                src="https://app-assets.freewaycup.de/sponsorlogo/daryse-black.svg"
                onClick={() => window.open("https://www.daryse.com/", "_blank")}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <Burger
            opened={navbarOpen}
            onClick={() => toggleNavbarOpen()}
            hiddenFrom="sm"
            size="md"
            title="Navigation"
          />
        </div>
      </AppShell.Header>
    </>
  );
};
