import { FC } from "react";
import { Time } from "../../../../../Components/Time";
import { TeamName } from "../../../../../Components/TeamName";
import { TimePenaltyCountdown } from "../../../Game/Components/TimePenaltyCountdown";
import { Grid } from "@mantine/core";
import { useGameTime } from "../../../../../hooks/useGameTime";
import { useGame } from "../../../../../hooks/useGame.ts";

type DisplayGameProps = {
  gameId: string;
};

export const DisplayGame: FC<DisplayGameProps> = (props) => {
  const { gameId } = props;

  const time = useGameTime(gameId);
  const game = useGame(gameId);

  const homeTeamId = game.data?.actualTeams.homeTeamId;
  const guestTeamId = game.data?.actualTeams.guestTeamId;

  if (!game.isSuccess || !homeTeamId || !guestTeamId) {
    return <></>;
  }

  return (
    <>
      <Grid
        style={{
          fontSize: 82,
          padding: 15,
          paddingBottom: 0,
          lineHeight: "60px",
          margin: 0,
          fontWeight: 700,
        }}
        align="center"
      >
        <Grid.Col
          span={4}
          style={{
            textAlign: "left",
            color: game.data.clockRunning ? "lime" : "red",
          }}
        >
          <Time leadingSpace time={time} />
        </Grid.Col>
        <Grid.Col span={2} style={{ textAlign: "left" }}>
          <TeamName short teamId={homeTeamId} />
        </Grid.Col>
        <Grid.Col span={4} style={{ textAlign: "center" }}>
          {game.data.score.home}:{game.data.score.guest}
        </Grid.Col>
        <Grid.Col span={2} style={{ textAlign: "right" }}>
          <TeamName short teamId={guestTeamId} />
        </Grid.Col>
      </Grid>
      <Grid
        style={{
          padding: 15,
          paddingTop: 0,
          margin: 0,
        }}
      >
        <Grid.Col span={4} />
        <Grid.Col
          span={4}
          style={{
            textAlign: "left",
            fontSize: 30,
            lineHeight: 1.3,
          }}
        >
          {game.data.timePenalties
            .filter((tp) => tp.teamId == homeTeamId)
            .filter((tp) => tp.time - tp.duration < time)
            .filter((tp) => !tp.finishedByGoalId)
            .slice(0, 2)
            .map((tp) => (
              <div key={tp.timePenaltyId}>
                <TimePenaltyCountdown time={time} timePenalty={tp} />
              </div>
            ))}
        </Grid.Col>
        <Grid.Col
          span={4}
          style={{
            textAlign: "right",
            fontSize: 30,
            lineHeight: 1.3,
          }}
        >
          {game.data.timePenalties
            .filter((tp) => tp.teamId == guestTeamId)
            .filter((tp) => tp.time - tp.duration < time)
            .filter((tp) => !tp.finishedByGoalId)
            .slice(0, 2)
            .map((tp) => (
              <div key={tp.timePenaltyId}>
                <TimePenaltyCountdown time={time} timePenalty={tp} />
              </div>
            ))}
        </Grid.Col>
      </Grid>
    </>
  );
};
