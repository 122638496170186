import { FC } from "react";
import { ComboboxData, Loader, Select, Tabs } from "@mantine/core";
import {
  IconAward,
  IconAwardOff,
  IconList,
  IconShirtSport,
} from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import {
  listGamesByCompetition,
  listTeams,
} from "../../../../openapi/api-client";
import { useCompetition } from "../../../../hooks/useCompetition.ts";

export type TeamRefType = "team" | "winner" | "loser" | "group";

interface TeamReferenceSelectionProps {
  competitionId: string;
  teamRefType: TeamRefType;
  onTabChange: (newTab: TeamRefType) => void;
  formProps: {
    winner: { value: string; onChange: () => void };
    loser: { value: string; onChange: () => void };
    team: { value: string; onChange: () => void };
    group: { value: string; onChange: () => void };
    position: { value: string; onChange: () => void };
  };
  selectedGroupId: string | null;
}

const isTeamRefType = (value: string): value is TeamRefType => {
  return ["winnder", "loser", "team", "group"].includes(value);
};

export const TeamReferenceSelection: FC<TeamReferenceSelectionProps> = (
  props,
) => {
  const {
    teamRefType,
    competitionId,
    selectedGroupId,
    onTabChange,
    formProps,
  } = props;

  const competition = useCompetition(competitionId);

  const games = useQuery({
    queryKey: ["games", { competitionId }],
    queryFn: async () => {
      const response = await listGamesByCompetition({ competitionId });
      return response.data;
    },
  });

  const teams = useQuery({
    queryKey: ["teams"],
    queryFn: async () => {
      const response = await listTeams({}); // todo: getTeamsByCompetition
      return response.data;
    },
  });

  if (!games.isSuccess || !teams.isSuccess || !competition.isSuccess) {
    return <Loader />;
  }

  // todo: this is pretty hacky
  const selectedGroup = competition.data.groups.find(
    (g) => g.id == selectedGroupId,
  );
  const groupPositionValues: ComboboxData =
    selectedGroup?.teamIds.map((_value, index) => ({
      value: (index + 1).toString(),
      label: (index + 1).toString(),
    })) ?? [];

  return (
    <Tabs
      defaultValue={teamRefType}
      onChange={(value) => {
        if (!value || !isTeamRefType(value)) {
          return;
        }

        onTabChange(value);
      }}
    >
      <Tabs.List>
        <Tabs.Tab value="winner" leftSection={<IconAward size={14} />}>
          Gewinner
        </Tabs.Tab>
        <Tabs.Tab value="loser" leftSection={<IconAwardOff size={14} />}>
          Verlierer
        </Tabs.Tab>
        <Tabs.Tab value="team" leftSection={<IconShirtSport size={14} />}>
          Team
        </Tabs.Tab>
        <Tabs.Tab value="group" leftSection={<IconList size={14} />}>
          Gruppenteilnehmer
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="winner" pt="xs">
        <Select
          label="Spiel"
          placeholder="Spiel auswählen"
          searchable
          nothingFoundMessage="Kein passendes Spiel"
          data={games.data.games.map((g) => ({
            value: g.id,
            label: g.name,
          }))}
          {...formProps.winner}
        />
      </Tabs.Panel>

      <Tabs.Panel value="loser" pt="xs">
        <Select
          label="Spiel"
          placeholder="Spiel auswählen"
          searchable
          nothingFoundMessage="Kein passendes Spiel"
          data={games.data.games.map((g) => ({
            value: g.id,
            label: g.name,
          }))}
          {...formProps.loser}
        />
      </Tabs.Panel>

      <Tabs.Panel value="team" pt="xs">
        <Select
          label="Mannschaft"
          placeholder="Mannschaft auswählen"
          searchable
          nothingFoundMessage="Kein passendes Spiel"
          data={teams.data.teams
            .filter((t) => competition.data.teamIds.includes(t.id))
            .map((t) => ({ value: t.id, label: t.name }))}
          {...formProps.team}
        />
      </Tabs.Panel>

      <Tabs.Panel value="group" pt="xs">
        <Select
          label="Gruppe"
          placeholder="Gruppe auswählen"
          searchable
          nothingFoundMessage="Keine passende Gruppe"
          data={competition.data.groups.map((g) => ({
            label: g.name,
            value: g.id,
          }))}
          {...formProps.group}
        />
        <Select
          label="Position"
          placeholder="Position auswählen"
          nothingFoundMessage="Keine Position"
          disabled={!groupPositionValues}
          data={groupPositionValues}
          {...formProps.position}
        />
      </Tabs.Panel>
    </Tabs>
  );
};
