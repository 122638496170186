import { FC } from "react";
import { Center, Loader } from "@mantine/core";

export const CenteredLoader: FC = () => {
  return (
    <Center py="xl">
      <Loader size="xl" variant="dots" color="fwcBlue.9" />
    </Center>
  );
};
