import { Page } from "../../../Components/Page";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { gameChangeDateTime } from "../../../openapi/api-client";
import { Button, Group, Loader, Stack } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { DateTimePicker } from "@mantine/dates";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { useGame } from "../../../hooks/useGame.ts";

export const ChangeDateTime: FC = () => {
  const { gameId } = useRequiredParams(["gameId"]);
  const navigate = useNavigate();

  const form = useForm<{
    date: Date;
  }>({
    initialValues: {
      date: new Date(),
    },
  });

  const game = useGame(gameId);

  useEffect(() => {
    if (!game.isSuccess) {
      return;
    }

    form.setValues({
      date: new Date(game.data.dateTime),
    });
  }, [game.data, game.isSuccess]);

  const changeDateTimeMutation = useMutation({
    mutationFn: gameChangeDateTime,
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (!game.isSuccess) {
      throw new Error("game not loaded");
    }

    await changeDateTimeMutation.mutateAsync({
      id: game.data.id,
      dateTime: values.date.toISOString(),
    });

    navigate(`../../..`, { relative: "path" });
  });

  if (!game.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={`Zeit von ${game.data.name} ändern`}>
      <form onSubmit={onSubmit}>
        <Stack align="flex-start" justify="flex-start">
          <Group>
            <DateTimePicker
              label="Datum"
              clearable={false}
              required
              {...form.getInputProps("date")}
            />
          </Group>

          <Group mt="md">
            <Button type="submit">Zeit ändern</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
