import { FC } from "react";
import { Image } from "@mantine/core";

interface LogoProps {
  onClick: () => void;
}

export const Logo: FC<LogoProps> = (props) => {
  const { onClick } = props;

  return (
    <div style={{ width: 118 }}>
      <Image
        src="/img/fwc-logo/FWC Breit mit Logos blau.svg"
        height={30}
        width={118}
        fit="contain"
        onClick={onClick}
        alt="FreewayCup"
        style={{ cursor: "pointer" }}
      />
    </div>
  );
};
