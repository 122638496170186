import { Page } from "../../../Components/Page";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { createPlayer, listTeams } from "../../../openapi/api-client";
import { v4 as uuid } from "uuid";
import {
  Button,
  Group,
  Loader,
  NumberInput,
  Select,
  Stack,
  Switch,
  TextInput,
} from "@mantine/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { DatePickerInput } from "@mantine/dates";

export const PlayerNew: FC = () => {
  const navigate = useNavigate();

  const teamData = useQuery({
    queryKey: ["teams"],
    queryFn: async () => {
      const response = await listTeams({});
      return response.data;
    },
  });

  const form = useForm<{
    firstName: string;
    lastName: string;
    birthday: Date;
    teamId: string;
    number: number;
    isGoalkeeper: boolean;
  }>({
    initialValues: {
      firstName: "",
      lastName: "",
      birthday: DateTime.now().minus({ years: 15 }).toJSDate(),
      teamId: "",
      number: 1,
      isGoalkeeper: false,
    },
  });

  const createPlayerMutation = useMutation({ mutationFn: createPlayer });

  const onSubmit = form.onSubmit(async (values) => {
    console.log(values);

    await createPlayerMutation.mutateAsync({
      id: uuid(),
      firstName: values.firstName,
      lastName: values.lastName,
      birthday: values.birthday.toISOString(),
      teamId: values.teamId,
      number: values.number,
      isGoalkeeper: values.isGoalkeeper,
    });

    navigate(`..`, { relative: "path" });
  });

  if (!teamData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={`Neuer Spieler`}>
      <form onSubmit={onSubmit}>
        <Stack align="flex-start" justify="flex-start">
          <Group>
            <TextInput
              placeholder="Max"
              label="Vorname"
              required
              {...form.getInputProps("firstName")}
            />

            <TextInput
              placeholder="Mustermann"
              label="Nachname"
              required
              {...form.getInputProps("lastName")}
            />
          </Group>

          <DatePickerInput
            label="Geburtstag"
            required
            clearable={false}
            {...form.getInputProps("birthday")}
          />

          <Group>
            <Select
              label="Mannschaft"
              placeholder="Mannschaft auswählen"
              searchable
              required
              nothingFoundMessage="keine passende Mannschaft gefunden"
              data={teamData.data.teams.map((t) => ({
                value: t.id,
                label: t.name,
              }))}
              {...form.getInputProps("teamId")}
            />
            <NumberInput
              placeholder="0"
              label="Trikotnummer"
              required
              min={1}
              {...form.getInputProps("number")}
            />
          </Group>

          <Switch label="Torwart" {...form.getInputProps("isGoalkeeper")} />

          <Group mt="md">
            <Button type="submit">Spieler anlegen</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
