import { FC, useContext } from "react";
import { Stack, Title } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { listGamesByCompetition } from "../../../../openapi/api-client";
import { GameList } from "../Widgets/GameList";
import { CenteredLoader } from "../../../../Components/CenteredLoader";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";
import { useCompetition } from "../../../../hooks/useCompetition.ts";

export const Games: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const competition = useCompetition(competitionId);
  const games = useQuery({
    queryKey: ["games", { competitionId }],
    queryFn: async () => {
      const gamesData = await listGamesByCompetition({ competitionId });
      return gamesData.data;
    },
  });

  const gamesWithoutMatchday =
    games.data?.games.filter((g) => !g.matchdayId) ?? [];
  const gamesWithMatchday =
    games.data?.games.filter((g) => !!g.matchdayId) ?? [];

  return (
    <Stack gap="lg">
      <Title order={2}>Alle Spiele</Title>
      {games.isSuccess && competition.isSuccess ? (
        <>
          {gamesWithoutMatchday.length > 0 && (
            <GameList games={gamesWithoutMatchday} />
          )}
          {competition.data.matchdays.map((m) => (
            <div key={m.id}>
              <Title
                order={3}
                size="h4"
                id={`matchday-${m.name.toLowerCase()}`}
              >
                {m.name}
              </Title>
              <GameList
                games={gamesWithMatchday.filter((g) => g.matchdayId == m.id)}
              />
            </div>
          ))}
        </>
      ) : (
        <CenteredLoader />
      )}
    </Stack>
  );
};
