import React, { FC, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPlayer, getTopscorer } from "../../../openapi/api-client";
import { PlayerNumber } from "../../../Components/PlayerNumber";
import { PlayerName } from "../../../Components/PlayerName";
import { Table, Group, Loader, Text, Image } from "@mantine/core";
import { CompetitionContext } from "../../../CompetitionContext.tsx";
import { TeamLogo } from "../../../Components/TeamLogo.tsx";

const tdStyle: React.CSSProperties = {
  paddingTop: 15,
  paddingBottom: 15,
  paddingLeft: 30,
  paddingRight: 30,
  color: "white",
};

export const TopscorerFullscreen: FC = () => {
  const competitionId = useContext(CompetitionContext);

  const topscorerData = useQuery({
    queryKey: ["topscorer", { competitionId }],
    queryFn: async () => {
      const topscorer = await getTopscorer({ competitionId });
      return topscorer.data;
    },
  });

  if (!topscorerData.isSuccess) {
    return <></>;
  }

  const scorer = topscorerData.data.topscorer.slice(0, 5);

  return (
    <div
      style={{
        backgroundColor: "black",
        width: 1920,
        height: 1080,
        padding: 50,
      }}
    >
      <Group justify="space-between" style={{ marginBottom: 30 }}>
        <Text size="110px" c="white" fw={900}>
          Unsere Topscorer
        </Text>
        <Image
          src="/img/fwc-logo/FWC Breit mit Logos weiß.svg"
          height={130}
          fit="contain"
          alt="FreewayCup"
        />
      </Group>

      <Table
        style={{
          width: "100%",
          fontSize: 64,
          borderCollapse: "collapse",
        }}
      >
        <Table.Thead>
          <Table.Tr
            style={{
              borderBottom: "10px solid white",
            }}
          >
            <Table.Th colSpan={3} style={{ ...tdStyle }}></Table.Th>
            <Table.Th
              style={{ ...tdStyle, textAlign: "center", fontWeight: 700 }}
            >
              T
            </Table.Th>
            <Table.Th
              style={{ ...tdStyle, textAlign: "center", fontWeight: 700 }}
            >
              A
            </Table.Th>
            <Table.Th
              style={{ ...tdStyle, textAlign: "center", fontWeight: 700 }}
            >
              S
            </Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {scorer.map((t, index) => (
            <PlayerRow
              key={t.playerId}
              playerId={t.playerId}
              position={index + 1}
              assists={t.assists}
              goals={t.goals}
            />
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
};

const PlayerRow: FC<{
  playerId: string;
  position: number;
  assists: number;
  goals: number;
}> = (props) => {
  const { playerId, position, assists, goals } = props;

  const playerData = useQuery({
    queryKey: ["player", { playerId }],
    queryFn: async () => {
      const response = await getPlayer({ playerId });
      return response.data;
    },
  });

  if (!playerData.isSuccess) {
    return (
      <Table.Tr>
        <Table.Td colSpan={5}>
          <Loader />
        </Table.Td>
      </Table.Tr>
    );
  }

  return (
    <Table.Tr style={{ borderBottom: "5px solid #444444" }}>
      <Table.Td style={{ ...tdStyle, textAlign: "right" }}>
        {position}.
      </Table.Td>
      <Table.Td>
        <TeamLogo height={80} teamId={playerData.data.teamId} />
      </Table.Td>
      <Table.Td style={{ ...tdStyle }}>
        <PlayerName firstname playerId={playerId} /> (Nr.{" "}
        <PlayerNumber playerId={playerId} />)
      </Table.Td>
      <Table.Td style={{ ...tdStyle, textAlign: "center" }}>{goals}</Table.Td>
      <Table.Td style={{ ...tdStyle, textAlign: "center" }}>{assists}</Table.Td>
      <Table.Td style={{ ...tdStyle, textAlign: "center" }}>
        {goals + assists}
      </Table.Td>
    </Table.Tr>
  );
};
