import { Text, Group, Loader } from "@mantine/core";
import { FC } from "react";
import { Time } from "../../../../../Components/Time";
import { useMutation } from "@tanstack/react-query";
import { gameRevokeGoal } from "../../../../../openapi/api-client";
import { PlayerNumber } from "../../../../../Components/PlayerNumber";
import { PlayerName } from "../../../../../Components/PlayerName";
import { useGame } from "../../../../../hooks/useGame.ts";

interface GameProps {
  gameId: string;
  goalId: string;
}

export const Goal: FC<GameProps> = (props) => {
  const { gameId, goalId } = props;

  const revokeGoalMutation = useMutation({ mutationFn: gameRevokeGoal });

  const game = useGame(gameId);

  if (!game.isSuccess) {
    return <Loader />;
  }

  const goal = game.data.goals.find((g) => g.goalId == goalId);
  if (!goal) {
    throw new Error("goal not found");
  }

  return (
    <>
      <Text c="dimmed" size="sm">
        <PlayerName firstname playerId={goal.playerId} />, Nr.{" "}
        <PlayerNumber playerId={goal.playerId} />
      </Text>
      <Text size="xs" mt={4}>
        <Group>
          <Time time={goal.time} />
          {!game.data.regularTimeFreezed && (
            <Text
              variant="link"
              c="dimmed"
              style={{ cursor: "pointer" }}
              onClick={async () =>
                await revokeGoalMutation.mutateAsync({ id: gameId, goalId })
              }
            >
              Löschen
            </Text>
          )}
        </Group>
      </Text>
    </>
  );
};
