import { FC, useContext } from "react";
import { Text, Title, Image, Group, Box, Stack, Table } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { listPlayersByTeam } from "../../../../openapi/api-client";
import { TeamLogo } from "../../../../Components/TeamLogo";
import { useRequiredParams } from "../../../../hooks/useRequiredParams";
import { TeamName } from "../../../../Components/TeamName";
import { PlayerNumber } from "../../../../Components/PlayerNumber";
import { PlayerName } from "../../../../Components/PlayerName";
import { CenteredLoader } from "../../../../Components/CenteredLoader";
import { useTeam } from "../../../../hooks/useTeam.ts";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";
import { useTeamGames } from "../../../../hooks/useTeamGames.ts";
import { GameList } from "../Widgets/GameList.tsx";

export const Team: FC = () => {
  const { teamId } = useRequiredParams(["teamId"]);
  const competitionId = useContext(CompetitionContext);
  const teamData = useTeam(teamId);

  const teamPlayersData = useQuery({
    queryKey: ["players", { teamId }],
    queryFn: async () => {
      const players = await listPlayersByTeam({ teamId });
      return players.data;
    },
  });

  const teamGames = useTeamGames(competitionId, teamId);

  if (!teamData.isSuccess) {
    return <CenteredLoader />;
  }

  return (
    <Stack>
      <Title order={2}>
        <Group>
          <Box w={30}>
            <TeamLogo teamId={teamId} height={30} />
          </Box>
          <Box>
            <TeamName teamId={teamId} />
          </Box>
        </Group>
      </Title>
      {teamData.data.photo && (
        <Box>
          <Image
            src={teamData.data.photo.filePath}
            fallbackSrc={`https://placehold.co/1600x900?text=${teamData.data.name}`}
            alt="Teamfoto"
          />
        </Box>
      )}
      {teamData.data.sponsor && (
        <Group justify="apart">
          <Box>
            <Text c="dimmed">Präsentiert von</Text>
          </Box>
          <Box w="50%">
            <Image
              onClick={() =>
                teamData.data.sponsor!.url
                  ? window.open(teamData.data.sponsor!.url, "_blank")
                  : {}
              }
              height={50}
              fit="contain"
              src={teamData.data.sponsor.logoFilePath}
              fallbackSrc={`https://placehold.co/500x500?text=${teamData.data.sponsor.name}`}
              alt={teamData.data.sponsor.name}
              style={{ cursor: "pointer" }}
            />
          </Box>
        </Group>
      )}
      <Title order={3}>Spiele</Title>
      {teamGames.isSuccess ? (
        <GameList games={teamGames.data.games} />
      ) : (
        <CenteredLoader />
      )}
      {teamPlayersData.isSuccess ? (
        teamPlayersData.data.players.length > 0 && (
          <>
            <Title order={3}>Das Team</Title>
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th style={{ width: "1%", textAlign: "right" }}>
                    #
                  </Table.Th>
                  <Table.Th>Name</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {teamPlayersData.data.players.map((p) => (
                  <Table.Tr key={p.id}>
                    <Table.Td style={{ textAlign: "right" }}>
                      <PlayerNumber playerId={p.id} />
                    </Table.Td>
                    <Table.Td>
                      <PlayerName firstname playerId={p.id} />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </>
        )
      ) : (
        <CenteredLoader />
      )}
      {teamData.data.staff && (
        <Text>
          <strong>Trainer-/Betreuerstab:</strong> {teamData.data.staff}
        </Text>
      )}
    </Stack>
  );
};
