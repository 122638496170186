import React, { FC, useContext } from "react";
import { getTeam } from "../../../openapi/api-client";
import { useQuery } from "@tanstack/react-query";
import { Image, Text } from "@mantine/core";
import { CompetitionContext } from "../../../CompetitionContext.tsx";
import { useCurrentGame } from "../../../hooks/useCurrentGame.ts";

const teamBoxStyle: React.CSSProperties = {
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  borderBottomWidth: 20,
  borderBottomStyle: "solid",
  textAlign: "center",
  padding: 60,
  width: 600,
  height: 800,
  lineHeight: 1.1,
};

export const NextMatch: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const currentGameData = useCurrentGame(competitionId);

  const homeTeam = useQuery({
    queryKey: [
      "team",
      { teamId: currentGameData.data?.actualTeams.homeTeamId },
    ],
    queryFn: async () => {
      if (!currentGameData.data?.actualTeams.homeTeamId) {
        throw Error("game not loaded");
      }

      const response = await getTeam({
        teamId: currentGameData.data.actualTeams.homeTeamId,
      });
      return response.data;
    },
    enabled: currentGameData.isSuccess,
  });

  const guestTeam = useQuery({
    queryKey: [
      "team",
      { teamId: currentGameData.data?.actualTeams.guestTeamId },
    ],
    queryFn: async () => {
      if (!currentGameData.data?.actualTeams.guestTeamId) {
        throw Error("game not loaded");
      }

      const response = await getTeam({
        teamId: currentGameData.data.actualTeams.guestTeamId,
      });
      return response.data;
    },
    enabled: currentGameData.isSuccess,
  });

  if (
    !currentGameData.isSuccess ||
    !homeTeam.isSuccess ||
    !guestTeam.isSuccess
  ) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 30,
      }}
    >
      <div style={{ ...teamBoxStyle, borderColor: homeTeam.data.primaryColor }}>
        <div
          style={{
            marginBottom: 60,
            height: 475,
            width: 500,
            backgroundImage: `url(${homeTeam.data.logoFilePath})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <div
          style={{
            width: 500,
          }}
        >
          {homeTeam.data.sponsor && (
            <>
              <Text c="dimmed" size="24px" mb="sm">
                präsentiert von
              </Text>
              <Image
                src={homeTeam.data.sponsor.logoFilePath}
                height={75}
                fit="contain"
              />
            </>
          )}
        </div>
      </div>

      <div
        style={{ ...teamBoxStyle, borderColor: guestTeam.data.primaryColor }}
      >
        <div
          style={{
            marginBottom: 60,
            height: 475,
            width: 500,
            backgroundImage: `url(${guestTeam.data.logoFilePath})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <div>
          {guestTeam.data.sponsor && (
            <>
              <Text c="dimmed" size="24px" mb="sm">
                präsentiert von
              </Text>
              <Image
                src={guestTeam.data.sponsor.logoFilePath}
                height={75}
                fit="contain"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
