import { FC } from "react";
import { Loader, Table, Title, Text, Stack } from "@mantine/core";
import { TeamName } from "../../../../Components/TeamName";
import { DateTime } from "luxon";
import { usePlayers } from "../../../../hooks/usePlayers.ts";
import { useTeam } from "../../../../hooks/useTeam.ts";

type TeamLineupProps = {
  teamId: string;
};

export const TeamLineup: FC<TeamLineupProps> = (props) => {
  const { teamId } = props;

  const players = usePlayers(teamId);
  const team = useTeam(teamId);

  if (!players.isSuccess || !team.isSuccess) {
    return <Loader />;
  }

  return (
    <Stack>
      <Title order={2}>
        <TeamName teamId={teamId} />
      </Title>
      <Text>{team.data.staff}</Text>
      <Table highlightOnHover mt="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Nummer</Table.Th>
            <Table.Th>Nachname</Table.Th>
            <Table.Th>Vorname</Table.Th>
            <Table.Th>Geburtstag</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {players.data.map((p) => {
            const birthday = DateTime.fromISO(p.birthday);
            const age = Math.floor(DateTime.now().diff(birthday).as("year"));

            return (
              <Table.Tr key={p.id}>
                <Table.Td>{p.number}</Table.Td>
                <Table.Td>{p.lastName}</Table.Td>
                <Table.Td>{p.firstName}</Table.Td>
                <Table.Td>
                  {birthday.toFormat("dd.MM.yyyy")} ({age})
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};
