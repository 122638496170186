import { Page } from "../../../Components/Page";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { planGame } from "../../../openapi/api-client";
import { v4 as uuid } from "uuid";
import {
  Button,
  Group,
  Loader,
  NumberInput,
  Select,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import {
  TeamReferenceSelection,
  TeamRefType,
} from "./Components/TeamReferenceSelection";
import { DateTimePicker } from "@mantine/dates";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { useCompetition } from "../../../hooks/useCompetition.ts";

type FormTeamRef = {
  refType: TeamRefType;
  winnerTeam: string;
  loserTeam: string;
  teamId: string;
  groupId: string;
  position: string;
};

export const GameNew: FC = () => {
  const { competitionId } = useRequiredParams(["competitionId"]);

  const navigate = useNavigate();

  const form = useForm<{
    name: string;
    durationInMinutes: number;
    plannedTeams: { home: FormTeamRef; guest: FormTeamRef };
    date: Date;
    groupId: string;
    matchdayId: string;
  }>({
    initialValues: {
      name: "",
      groupId: "",
      matchdayId: "",
      durationInMinutes: 1,
      date: new Date(),
      plannedTeams: {
        home: {
          refType: "team",
          winnerTeam: "",
          loserTeam: "",
          teamId: "",
          groupId: "",
          position: "1",
        },
        guest: {
          refType: "team",
          winnerTeam: "",
          loserTeam: "",
          teamId: "",
          groupId: "",
          position: "1",
        },
      },
    },
  });

  const competition = useCompetition(competitionId);

  useEffect(() => {
    if (!competition.isSuccess) {
      return;
    }

    form.setFieldValue("date", new Date(competition.data.start));
    form.setFieldValue("time", new Date(competition.data.start));
  }, [competition.data, competition.isSuccess]);

  const planGameMutation = useMutation({ mutationFn: planGame });

  const onSubmit = form.onSubmit(async (values) => {
    const homeTeamRefType = values.plannedTeams.home.refType;
    const guestTeamRefType = values.plannedTeams.guest.refType;

    const homeTeamRef =
      homeTeamRefType == "winner"
        ? { winnerTeam: values.plannedTeams.home.winnerTeam }
        : homeTeamRefType == "loser"
          ? { loserTeam: values.plannedTeams.home.loserTeam }
          : homeTeamRefType == "team"
            ? { teamId: values.plannedTeams.home.teamId }
            : {
                groupId: values.plannedTeams.home.groupId,
                position: parseInt(values.plannedTeams.home.position),
              };

    const guestTeamRef =
      guestTeamRefType == "winner"
        ? { winnerTeam: values.plannedTeams.guest.winnerTeam }
        : guestTeamRefType == "loser"
          ? { loserTeam: values.plannedTeams.guest.loserTeam }
          : guestTeamRefType == "team"
            ? { teamId: values.plannedTeams.guest.teamId }
            : {
                groupId: values.plannedTeams.guest.groupId,
                position: parseInt(values.plannedTeams.guest.position),
              };

    await planGameMutation.mutateAsync({
      id: uuid(),
      name: values.name,
      competitionId,
      groupId: values.groupId != "" ? values.groupId : undefined,
      matchdayId: values.matchdayId,
      dateTime: values.date.toISOString(),
      duration: values.durationInMinutes * 60,
      plannedTeams: {
        home: homeTeamRef,
        guest: guestTeamRef,
      },
    });

    navigate(`../..`, { relative: "path" });
  });

  if (!competition.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={`Spiel planen`}>
      <form onSubmit={onSubmit}>
        <Stack align="flex-start" justify="flex-start">
          <TextInput
            placeholder="Halbfinale 1"
            label="Spielname"
            required
            {...form.getInputProps("name")}
          />

          <Select
            label="Gruppe"
            placeholder="Gruppe auswählen"
            description="Spiele ohne Gruppe sind KO-Spiele"
            searchable
            clearable
            nothingFoundMessage="Keine Gruppe angelegt"
            data={competition.data.groups.map((g) => ({
              label: g.name,
              value: g.id,
            }))}
            {...form.getInputProps("groupId")}
          />

          <Select
            label="Matchday"
            placeholder="Matchday auswählen"
            description="Wird zur Gruppierung der Anzeige genutzt"
            searchable
            required
            clearable={false}
            nothingFoundMessage="Kein Matchday angelegt"
            data={competition.data.matchdays.map((g) => ({
              label: g.name,
              value: g.id,
            }))}
            {...form.getInputProps("matchdayId")}
          />

          <Group align="end">
            <NumberInput
              label="Spieldauer"
              description="Angabe in Minuten"
              required
              {...form.getInputProps("durationInMinutes")}
            />
            <Button onClick={() => form.setFieldValue("durationInMinutes", 12)}>
              12 Min.
            </Button>
            <Button onClick={() => form.setFieldValue("durationInMinutes", 15)}>
              15 Min.
            </Button>
          </Group>

          <Title order={2}>Zeit</Title>
          <Group>
            <DateTimePicker
              label="Zeitpunkt"
              clearable={false}
              required
              {...form.getInputProps("date")}
            />
          </Group>

          <Title order={2}>Heimmannschaft</Title>
          <TeamReferenceSelection
            competitionId={competitionId}
            onTabChange={(value) =>
              form.setFieldValue("plannedTeams.home.refType", value)
            }
            teamRefType={form.values.plannedTeams.home.refType}
            selectedGroupId={form.values.plannedTeams.home.groupId}
            formProps={{
              team: form.getInputProps("plannedTeams.home.teamId"),
              winner: form.getInputProps("plannedTeams.home.winnerTeam"),
              loser: form.getInputProps("plannedTeams.home.loserTeam"),
              group: form.getInputProps("plannedTeams.home.groupId"),
              position: form.getInputProps("plannedTeams.home.position"),
            }}
          />

          <Title order={2}>Gastmannschaft</Title>
          <TeamReferenceSelection
            competitionId={competitionId}
            onTabChange={(value) =>
              form.setFieldValue("plannedTeams.guest.refType", value)
            }
            teamRefType={form.values.plannedTeams.guest.refType}
            selectedGroupId={form.values.plannedTeams.guest.groupId}
            formProps={{
              team: form.getInputProps("plannedTeams.guest.teamId"),
              winner: form.getInputProps("plannedTeams.guest.winnerTeam"),
              loser: form.getInputProps("plannedTeams.guest.loserTeam"),
              group: form.getInputProps("plannedTeams.guest.groupId"),
              position: form.getInputProps("plannedTeams.guest.position"),
            }}
          />

          <Group mt="md">
            <Button type="submit">Spiel planen</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
