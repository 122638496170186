import React, { FC, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPlayer, getTopscorer } from "../../../openapi/api-client";
import { PlayerNumber } from "../../../Components/PlayerNumber";
import { PlayerName } from "../../../Components/PlayerName";
import { Loader, Table } from "@mantine/core";
import { CompetitionContext } from "../../../CompetitionContext.tsx";
import { TeamLogo } from "../../../Components/TeamLogo.tsx";

const tdStyle: React.CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: 24,
  fontFamily: "Chivo",
};

export const Topscorer: FC = () => {
  const competitionId = useContext(CompetitionContext);

  const topscorerData = useQuery({
    queryKey: ["topscorer", { competitionId }],
    queryFn: async () => {
      const topscorer = await getTopscorer({ competitionId });
      return topscorer.data;
    },
  });

  if (!topscorerData.isSuccess) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div>
        <Table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 2px",
          }}
        >
          <Table.Thead>
            <Table.Tr
              style={{
                backgroundColor: "#194383",
                color: "white",
              }}
            >
              <Table.Th
                style={{
                  ...tdStyle,
                  textAlign: "left",
                  fontWeight: 900,
                  fontSize: 40,
                }}
                colSpan={3}
              >
                Topscorer
              </Table.Th>
              <Table.Th
                style={{
                  ...tdStyle,
                  textAlign: "center",
                  paddingTop: 20,
                  paddingBottom: 20,
                  fontWeight: 900,
                }}
              >
                Tore
              </Table.Th>
              <Table.Th
                style={{ ...tdStyle, textAlign: "center", fontWeight: 900 }}
              >
                Assists
              </Table.Th>
              <Table.Th
                style={{ ...tdStyle, textAlign: "center", fontWeight: 900 }}
              >
                Summe
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {topscorerData.data.topscorer.slice(0, 10).map((t, index) => (
              <PlayerRow
                key={t.playerId}
                playerId={t.playerId}
                position={index + 1}
                assists={t.assists}
                goals={t.goals}
              />
            ))}
          </Table.Tbody>
        </Table>
      </div>
    </div>
  );
};

const PlayerRow: FC<{
  playerId: string;
  position: number;
  assists: number;
  goals: number;
}> = (props) => {
  const { playerId, position, assists, goals } = props;

  const playerData = useQuery({
    queryKey: ["player", { playerId }],
    queryFn: async () => {
      const response = await getPlayer({ playerId });
      return response.data;
    },
  });

  if (!playerData.isSuccess) {
    return (
      <Table.Tr>
        <Table.Td colSpan={6}>
          <Loader />
        </Table.Td>
      </Table.Tr>
    );
  }

  return (
    <Table.Tr
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.95)",
      }}
    >
      <Table.Td style={{ ...tdStyle, textAlign: "right" }}>
        {position}.
      </Table.Td>
      <Table.Td>
        <TeamLogo height={35} teamId={playerData.data.teamId} />
      </Table.Td>
      <Table.Td style={{ ...tdStyle, width: 500, fontWeight: 700 }}>
        <PlayerName firstname playerId={playerId} /> (Nr.{" "}
        <PlayerNumber playerId={playerId} />)
      </Table.Td>
      <Table.Td style={{ ...tdStyle, textAlign: "center" }}>{goals}</Table.Td>
      <Table.Td style={{ ...tdStyle, textAlign: "center" }}>{assists}</Table.Td>
      <Table.Td style={{ ...tdStyle, textAlign: "center" }}>
        {goals + assists}
      </Table.Td>
    </Table.Tr>
  );
};
