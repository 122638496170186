import { FC } from "react";
import { AppShell, Grid, Box, Stack } from "@mantine/core";
import { AppHeader } from "./Components/Layout/AppHeader";
import { useMediaQuery, useToggle } from "@mantine/hooks";
import { Outlet, ScrollRestoration, useOutlet } from "react-router-dom";
import { Homepage } from "./Components/Pages/Homepage";
import { CurrentGame } from "./Components/Widgets/CurrentGame";
import { Navigation } from "./Components/Layout/Navigation";
import { Stream } from "./Components/Widgets/Stream";
import { OfflineIndicator } from "../../Components/OfflineIndicator.tsx";
import { SponsorAd } from "../../Components/SponsorAd.tsx";

export const App: FC = () => {
  const [navbarOpened, toggleNavbarOpen] = useToggle<boolean>();
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const outlet = useOutlet();

  return (
    <>
      <ScrollRestoration />
      <AppShell
        header={{ height: 70 }}
        navbar={{
          width: 250,
          breakpoint: "sm",
          collapsed: { mobile: !navbarOpened },
        }}
        padding="md"
        styles={(theme) => ({
          main: { backgroundColor: theme.colors.gray[0] },
        })}
      >
        <AppHeader
          navbarOpen={navbarOpened}
          toggleNavbarOpen={toggleNavbarOpen}
        />

        <Navigation
          navbarOpened={navbarOpened}
          closeNavbar={() => toggleNavbarOpen(false)}
        />

        <AppShell.Main>
          <Stack gap="lg">
            <OfflineIndicator />
            {isDesktop ? (
              <Grid>
                <Grid.Col span={4}>
                  {outlet != null ? <Outlet /> : <Homepage isDesktop />}
                </Grid.Col>
                <Grid.Col span={8}>
                  <Box w="100%" style={{ position: "sticky", top: 86 }}>
                    <Stream />
                    <SponsorAd />
                  </Box>
                </Grid.Col>
              </Grid>
            ) : (
              <>
                <CurrentGame />
                {outlet != null ? <Outlet /> : <Homepage />}
                <SponsorAd />
              </>
            )}
          </Stack>

          <div style={{ color: "#333", marginTop: 30, fontSize: 12 }}>
            © {new Date().getFullYear()}, FreewayCup -{" "}
            <a href="https://www.freewaycup.de/impressum" target={"_blank"}>
              Impressum
            </a>{" "}
            -{" "}
            <a
              href="https://www.freewaycup.de/datenschutzerklaerung/"
              target={"_blank"}
            >
              Datenschutz
            </a>
          </div>
          <div style={{ color: "#333", marginTop: 10, fontSize: 12 }}>
            <a href="https://freewaycup.de/app-feedback/" target={"_blank"}>
              Feedback zur App geben
            </a>
          </div>
          <div style={{ color: "#333", marginTop: 10, fontSize: 12 }}>
            Turnier-Technik, App und Live-Score von{" "}
            <a
              style={{ whiteSpace: "nowrap" }}
              href="https://www.patrickhilker.de/?utm_source=customer-link&utm_term=fwc-app-footer"
            >
              Patrick Hilker - Webdesign und Webentwicklung
            </a>
          </div>
        </AppShell.Main>
      </AppShell>
    </>
  );
};
