import { ActivityItem } from "./Components/2x1920x144/ActivityItem.tsx";

export const activityItems: ActivityItem[] = [
  {
    id: "3e1e52c8-1860-4675-a729-3b558f9f4829",
    label: "Müller",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-mueller.jpg",
  },
  {
    id: "0ef591ac-e124-42af-845d-4e9a2b43cfab",
    label: "Instagram",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-instagram.jpg",
  },
  {
    id: "a33f46d8-83de-4da2-bcea-36f6d98a301e",
    label: "Tombola",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-tombola.jpg",
  },
  {
    id: "2de23016-7051-402c-8c4f-6fb940bb954b",
    label: "App",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-app.jpg",
  },
  {
    id: "630b4fa8-8a4e-434b-86df-4df5d3c94323",
    label: "Trampolin (Sa., 14:00 Uhr)",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-2025-trampolin.jpg",
  },
  {
    id: "4a45f17f-aaf8-4645-991e-1323360b0a47",
    label: "Azubi-Cup (Sa., 16:15 Uhr)",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-2025-azubi-cup.jpg",
  },
  {
    id: "dde211bb-9986-42fe-8fcf-3250fe445777",
    label: "DDC (So., 15:00 Uhr)",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-2025-ddc.jpg",
  },
  {
    id: "f58a643f-9be9-42c5-84ff-16a1774b65ba",
    label: "Herzlich willkommen",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-herzlich-willkommen.jpg",
  },
  {
    id: "b1206766-c069-4a9c-8752-1fefc71d359a",
    label: "Bis morgen",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-bis-morgen.jpg",
  },
  {
    id: "f0519a04-ef66-408b-9b21-2b13c1869462",
    label: "Finale",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-finale.jpg",
  },
  {
    id: "8e56c641-4d16-4c98-a5c5-9fe8328d5b7e",
    label: "Herzlichen Glückwunsch",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-herzlichen-glueckwunsch.jpg",
  },
  {
    id: "e9536ff3-4356-4648-9700-e815c6e79e4b",
    label: "Bis nächstes Jahr",
    src: "https://app-assets.freewaycup.de/ledwall/activityitems/fwc-bis-naechstes-jahr.jpg",
  },
];
