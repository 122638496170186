import { FC } from "react";
import {
  IconCheck,
  IconCheckbox,
  IconPlayerPause,
  IconPlayerPlay,
} from "@tabler/icons-react";
import { Button } from "@mantine/core";
import { Time } from "../../../../Components/Time";

type TimeButtonProps = {
  start: () => void;
  pause: () => void;
  resume: () => void;
  freezeRegularTime: () => void;
  time: number;
  running: boolean;
  regularTimeOver: boolean;
  freezed: boolean;
  started: boolean;
  regieReady?: boolean;
};

export const TimeButton: FC<TimeButtonProps> = (props) => {
  const {
    time,
    running,
    regularTimeOver,
    freezed,
    started,
    start,
    pause,
    resume,
    freezeRegularTime,
    regieReady,
  } = props;
  return (
    <Button
      size="xl"
      color={running ? "green" : "red"}
      leftSection={
        freezed ? (
          <IconCheckbox size={80} />
        ) : regularTimeOver ? (
          <IconCheck size={80} />
        ) : running ? (
          <IconPlayerPause size={80} />
        ) : (
          <IconPlayerPlay size={80} />
        )
      }
      styles={() => ({
        root: { height: 150, fontSize: 60 },
      })}
      fullWidth
      disabled={freezed || !regieReady}
      onClick={
        regularTimeOver
          ? freezeRegularTime
          : !started
            ? start
            : running
              ? pause
              : resume
      }
    >
      {!regieReady ? (
        "Warte auf Regie"
      ) : !regularTimeOver ? (
        <Time time={time} />
      ) : freezed ? (
        <>Festgeschrieben</>
      ) : (
        <>Festschreiben</>
      )}
    </Button>
  );
};
