import React, { FC, useContext } from "react";
import { useGameTime } from "../../../hooks/useGameTime";
import { Time } from "../../../Components/Time";
import { TeamName } from "../../../Components/TeamName";
import { useDebouncedCurrentGame } from "../../../hooks/useDebouncedCurrentGame";
import { CompetitionContext } from "../../../CompetitionContext.tsx";
import { useTeam } from "../../../hooks/useTeam.ts";

const team: React.CSSProperties = {
  backgroundColor: "white",
  color: "black",
  width: 102,
  height: 38,
  fontSize: 24,
  fontFamily: "Chivo",
  fontWeight: 700,
  textAlign: "center",
};

const homeTeamStyle: React.CSSProperties = {
  ...team,
  borderLeftWidth: 6,
  borderLeftStyle: "solid",
};

const guestTeamStyle: React.CSSProperties = {
  ...team,
  borderRightWidth: 6,
  borderRightStyle: "solid",
};

const score: React.CSSProperties = {
  height: 38,
  width: 102,
  fontSize: 24,
  fontFamily: "Chivo Mono",
  fontWeight: 700,
  textAlign: "center",
  backgroundColor: "#194383",
  color: "white",
};

const timeStyle: React.CSSProperties = {
  height: 38,
  width: 102,
  fontSize: 24,
  fontFamily: "Chivo Mono",
  fontWeight: 700,
  textAlign: "center",
  backgroundColor: "white",
  marginLeft: 102,
};

export const Score: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const currentGameData = useDebouncedCurrentGame(competitionId);

  const homeTeam = useTeam(currentGameData.data?.actualTeams.homeTeamId);
  const guestTeam = useTeam(currentGameData.data?.actualTeams.guestTeamId);

  const time = useGameTime(currentGameData.data?.id) ?? 0;

  if (
    !currentGameData.isSuccess ||
    !homeTeam.isSuccess ||
    !guestTeam.isSuccess
  ) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        top: 75,
        left: 1450,
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            ...homeTeamStyle,
            borderColor: homeTeam.data.primaryColor,
          }}
        >
          <TeamName teamId={homeTeam.data.id} short />
        </div>
        <div style={score}>
          {currentGameData.data.score.home}–{currentGameData.data.score.guest}
        </div>
        <div
          style={{
            ...guestTeamStyle,
            borderColor: guestTeam.data.primaryColor,
          }}
        >
          <TeamName teamId={guestTeam.data.id} short />
        </div>
      </div>
      <div style={{ ...timeStyle }}>
        <Time time={time} />
      </div>
    </div>
  );
};
